var msalAuth = new msal.PublicClientApplication({
    auth: {
        clientId: oauthConfig.CLIENT_ID,
        authority: oauthConfig.ISSUER_URL,
        redirectUri: oauthConfig.REDIRECT_URI
    }});
msalAuth.handleRedirectPromise().then(function(tokenResponse) {
    if(tokenResponse!==null) {
        window.sessionStorage.setItem(oauthConfig.JWT, tokenResponse.idToken);
        window.sessionStorage.setItem(oauthConfig.ROLES, tokenResponse.idTokenClaims.roles);
        window.sessionStorage.removeItem(oauthConfig.LOGIN_INDICATOR);
        window.location.reload();
    }
}).catch(function(error) {
    console.log(error);
});

'use strict';
var app = angular.module('profileAdmin', ['ngRoute', 'ngCookies', 'ngSanitize', 'angular.filter', 'admin.config', 'angular-bind-html-compile',
    'ui.grid', 'ui.grid.selection', 'ui.grid.resizeColumns', 'ui.grid.pagination']);

app.config(["$routeProvider", "$httpProvider", "$locationProvider", "$qProvider", function($routeProvider, $httpProvider, $locationProvider, $qProvider){
   $routeProvider
       /**
        * Company Admin pages
        */
       .when ('/company-admin',
           {
               controller: 'CompanyAdminController',
               templateUrl: 'partials/company-admin/CompanyAdmin.html'
           })
       .when ('/view-company',
           {
               controller: 'ViewCompanyController',
               templateUrl: 'partials/company-admin/ViewCompany.html'
           })
       .when ('/edit-company',
           {
               controller: 'EditCompanyController',
               templateUrl: 'partials/company-admin/EditCompany.html'
           })
       .when ('/edit-company-domain',
           {
               controller: 'EditCompanyDomainController',
               templateUrl: 'partials/company-admin/EditCompanyDomain.html'
           })

       /**
        * User Pages
        */
       .when ('/unlock-account',
           {
               controller: 'UnlockAccountController',
               templateUrl: 'partials/users/UnlockAccount.html'
           })
       .when ('/reset-user-password',
           {
               controller: 'ResetUserPasswordController',
               templateUrl: 'partials/users/ResetUserPassword.html'
           })
       .when ('/search-users',
           {
               controller: 'UserSearchController',
               templateUrl: 'partials/users/UserSearch.html'
           })
       .when ('/send-activation-email',
           {
               controller: 'SendActivationEmailController',
               templateUrl: 'partials/users/SendActivationEmail.html'
           })
       .when ('/user-details',
           {
               controller: 'UserDetailsController',
               templateUrl: 'partials/users/UserDetails.html'
           })
       .when ('/user-details/:selectedTab',
           {
               controller: 'UserDetailsController',
               templateUrl: 'partials/users/UserDetails.html'
           })
       .when ('/edit-user-subscriptions',
           {
               controller: 'EditUserSubscriptionsController',
               templateUrl: 'partials/users/EditUserSubscriptions.html'
           })
       .when ('/dormant-users',
           {
               controller: 'UploadDormantUsersController',
               templateUrl: 'partials/users/UploadDormantUsers.html'
           })
       .when ('/bulk-delete-users',
           {
               controller: 'BulkDeleteUsersController',
               templateUrl:'partials/users/BulkDeleteUsers.html'
           })
       .when('/bulk-delete-users-by-domain',
           {
               controller: 'BulkDeleteUsersByDomainController',
               templateUrl: 'partials/users/BulkDeleteUsersByDomain.html'
           })
       .when('/view-federated-domains',
           {
               controller: 'ViewFederatedDomainsController',
               templateUrl: 'partials/users/ViewFederatedDomains.html'
           })


       /**
        * Email Preferences Pages
        */
       .when ('/optin-user',
           {
               controller: 'OptinUserController',
               templateUrl: 'partials/email-preferences/OptinUser.html'
           })
       .when ('/optout-user',
           {
               controller: 'OptoutUserController',
               templateUrl: 'partials/email-preferences/OptoutUser.html'
           })
       .when ('/check-optin-status',
           {
               controller: 'CheckOptinStatusController',
               templateUrl: 'partials/email-preferences/CheckOptinStatus.html'
           })
       .when ('/import-email-preferences',
           {
               controller: 'ImportEmailPreferencesController',
               templateUrl: 'partials/email-preferences/ImportEmailPreferences.html'
           })

       .when ('/delete-email-preferences',
           {
               controller: 'DeleteEmailPreferencesController',
               templateUrl: 'partials/email-preferences/DeleteEmailPreferences.html'
           })

       /**
        * Locale stuff
        */
       .when ('/manage-locales',
           {
               controller: 'ManageLocalesController',
               templateUrl: 'partials/locales/ManageLocales.html'
           })
       .when ('/edit-locale',
           {
               controller: 'EditLocaleController',
               templateUrl: 'partials/locales/EditLocale.html'
           })

       /**
        * Subscriptions Pages
        */
       .when ('/import-subscribers',
           {
               controller: 'ImportSubscribersController',
               templateUrl: 'partials/subscriptions/ImportSubscribers.html'
           })
       .when ('/import-unsubscribers',
           {
               controller: 'ImportUnSubscribersController',
               templateUrl: 'partials/subscriptions/ImportUnSubscribers.html'
           })
       .when ('/manage-subscriptions',
           {
               controller: 'ManageSubscriptionsController',
               templateUrl: 'partials/subscriptions/ManageSubscriptions.html'
           })
       .when ('/view-subscription',
           {
               controller: 'ViewSubscriptionController',
               templateUrl: 'partials/subscriptions/ViewSubscription.html'
           })
       .when ('/edit-subscription',
           {
               controller: 'EditSubscriptionController',
               templateUrl: 'partials/subscriptions/EditSubscription.html'
           })
       .when ('/create-subscription-filter',
           {
               controller: 'CreateSubscriptionFilterController',
               templateUrl: 'partials/subscriptions/CreateSubscriptionFilter.html'
           })
       .when ('/edit-subscription-filter',
           {
               controller: 'EditSubscriptionFilterController',
               templateUrl: 'partials/subscriptions/EditSubscriptionFilter.html'
           })
       .when ('/create-subscription',
           {
               controller: 'CreateSubscriptionController',
               templateUrl: 'partials/subscriptions/CreateSubscription.html'
           })

       /**
        *  Translations Pages
        */
       .when ('/import-translations',
           {
               controller: 'ImportTranslationsController',
               templateUrl: 'partials/translations/ImportTranslations.html'
           })
       .when ('/export-translations',
           {
               controller: 'ExportTranslationsController',
               templateUrl: 'partials/translations/ExportTranslations.html'
           })
       .when ('/manage-translation-versions',
           {
               controller: 'ManageTranslationVersionsController',
               templateUrl: 'partials/translations/ManageTranslationVersions.html'
           })
       .when ('/manage-translations',
           {
               controller: 'ManageTranslationsController',
               templateUrl: 'partials/translations/ManageTranslations.html'
           })
       .when ('/overwrite-from-remote-translations',
           {
               controller: 'OverwriteFromRemoteTranslationsController',
               templateUrl: 'partials/translations/OverwriteFromRemoteTranslations.html'
           })

       /**
        *  Access Check Pages
        */
       .when ('/view-access-restrictions',
           {
               controller: 'ViewAccessRestrictionsController',
               templateUrl: 'partials/access-checks/ViewAccessRestrictions.html'
           })
       .when ('/view-user-access-restrictions',
           {
               controller: 'ViewUserAccessRestrictionsController',
               templateUrl: 'partials/access-checks/ViewUserAccessRestrictions.html'
           })
       .when ( '/access-status-search',
           {
               controller: 'AccessStatusSearchController',
               templateUrl: 'partials/access-checks/AccessStatusSearch.html'
           }
       )

       /**
        * Other
        */
       .when ('/service-status',
           {
               controller: 'ServiceStatusController',
               templateUrl: 'partials/service-status/ServiceStatus.html'
           })
       .when ('/manage-environment',
           {
               controller: 'ManageEnvironmentController',
               templateUrl: 'partials/manage-environment/ManageEnvironment.html'
           })
	   .when ('/manage-internal-settings',
		   {
			   controller: 'ManageInternalSettingsController',
			   templateUrl: 'partials/manage-environment/ManageInternalSettings.html'
		   })
       .when ('/application-keys',
           {
               controller: 'ManageApplicationKeysController',
               templateUrl: 'partials/application-keys/ManageApplicationKeys.html'
           })



       .when ('/home',
           {
               controller: '',
               templateUrl: 'partials/Home.html'
           })
       .when ('/error',
           {
               controller: 'ErrorController',
               templateUrl: 'partials/error/Error.html'
           })
       .when ('/permissiondenied',
           {
               controller: 'ErrorController',
               templateUrl: 'partials/error/PermissionDenied.html'
           })
       .otherwise({ redirectTo:'/home' });

    $httpProvider.interceptors.push('AdminClientInterceptor');

	$locationProvider.hashPrefix('');

	$qProvider.errorOnUnhandledRejections(false);
}]);

app.run(["$http", "APP_KEY", "$rootScope", "AdminUtils", "AdminErrorHandler", "$cookies", "ADMIN_CONSTANTS", function ($http, APP_KEY, $rootScope, AdminUtils, AdminErrorHandler, $cookies, ADMIN_CONSTANTS) {
    //utility functions bound to root scope for access in partials
    $rootScope.AdminUtils = AdminUtils;
    $rootScope.AdminErrorHandler = AdminErrorHandler;
    $rootScope.ADMIN_CONSTANTS = ADMIN_CONSTANTS;

    //common headers
    $http.defaults.headers.common['X-SASProfile-AppKey'] = APP_KEY;
    $http.defaults.headers.common.Accept = "application/vnd.sas.profile-v3+json";
    $http.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
    $http.defaults.headers.put['Content-Type'] = 'application/x-www-form-urlencoded';

    var jwt = window.sessionStorage.getItem(oauthConfig.JWT);
    if(jwt){
        $http.defaults.headers.common['Authorization'] = 'Bearer ' + jwt;
    } else {
        AdminErrorHandler.handleLogin();
    }
}]);

app
    .constant('ADMIN_ERRORS', {
            INVALID_PARAMETER_ERROR: 400,
            UNAUTHORIZED_ERROR: 401,
            FORBIDDEN_ERROR: 403,
            CONFLICT_ERROR: 409,
            NOT_FOUND_ERROR: 404,
            INTERNAL_SERVER_ERROR: 500,
            SERVICE_UNAVAILABLE_ERROR: 503,
            UNPROCESSABLE_ENTITY_ERROR: 422
    });

app
    .constant('ADMIN_ACCESS_GROUPS', {
            COMPANY: 'www-auth-profile_company',
            NEWSLETTER: 'www-auth-profile_newsletter',

            PROFILE_VIEW: 'www-auth-profile_view',
            PROFILE_EDIT: 'www-auth-profile_edit',
            PROFILE_DELETE: 'www-auth-profile_delete',

            ACCESS_CONTROL: 'www-auth-profile_access-control',

            SUPERUSER: 'www-auth-profile_superuser',
            PROFILE_CONFIGURE: 'www-auth-profile_configure',
            TRANSLATION_EDIT: 'www-auth-profile_translation_edit'
    });

app
    .constant('ADMIN_CONSTANTS', {
        IMPORT_CHUNK_SIZE: 5000,
        DATE_FORMAT: 'MMM dd, yyyy hh:mm:ss a',
        ACCESS_RESTRICTIONS_PAGE_SIZE: 40,
        CLEAR_CACHE_TIME: 900000
    });

app.factory('AdminClient', ["$http", "$httpParamSerializerJQLike", "BASE_URL", "SERVICES_URL", "ADMIN_CONSTANTS", function ($http, $httpParamSerializerJQLike, BASE_URL, SERVICES_URL, ADMIN_CONSTANTS) {
    'use strict';

    var SERVICES_INT_URL = SERVICES_URL + '/profile/services/ia';

    //used for import functions
    function appendTransform(defaults, transform) {
        // We can't guarantee that the default transformation is an array
        defaults = angular.isArray(defaults) ? defaults : [defaults];

        // Append the new transformation to the defaults
        return defaults.concat(transform);
    }

    return {
        users: {
            unlock: function(email) {
                return $http({
                    method: 'PUT',
                    url: SERVICES_INT_URL + '/users/' + encodeURIComponent(email) + "/unlock"
                });
            },
            sendResetPasswordEmail: function(idOrEmail) {
                return $http({
                    method: 'POST',
                    url: SERVICES_INT_URL + '/users/resetPassword',
                    data: $httpParamSerializerJQLike(idOrEmail)
                });
            },
            forceOktaAdPush: function(idOrEmail) {
                return $http({
                    method: 'POST',
                    url: SERVICES_INT_URL + '/users/oktaPushToAd',
                    data: $httpParamSerializerJQLike(idOrEmail)
                });
            },
            activateOktaUser: function(idOrEmail) {
                return $http({
                    method: 'POST',
                    url: SERVICES_INT_URL + '/users/oktaActivateWithoutEmail',
                    data: $httpParamSerializerJQLike(idOrEmail)
                });
            },
            getUsers: function(searchTerms) {
                var queryUrl;
                for(var searchTermKey in searchTerms) {
                    if(searchTerms.hasOwnProperty(searchTermKey) && searchTerms[searchTermKey]) {
                        if(!queryUrl) {
                            queryUrl = '?';
                        }
                        else {
                            queryUrl = queryUrl.concat('&');
                        }
                        queryUrl = queryUrl.concat(searchTermKey + '=' + encodeURIComponent(searchTerms[searchTermKey]));
                    }
                }
                return $http({
                    method: 'GET',
                    url: SERVICES_INT_URL + '/users' + queryUrl
                });
            },
            sendActivationEmail: function(idOrEmail) {
                return $http({
                    method: 'POST',
                    url: SERVICES_INT_URL + '/users/activate',
                    data: $httpParamSerializerJQLike(idOrEmail)
                });
            },
            resendEmail: function(emailJobID) {
                return $http({
                    method: 'POST',
                    url: SERVICES_INT_URL + '/users/resend',
                    data: $httpParamSerializerJQLike(emailJobID)
                });
            },
            getLoginHistory: function(idOrEmail) {
                return $http({
                    method: 'GET',
                    url: SERVICES_INT_URL + '/users/' + idOrEmail + '/loginhistory'
                });
            },
            updateUser: function(idOrEmail, user) {
                return $http({
                    method: 'PUT',
                    url: SERVICES_INT_URL + '/users/' + idOrEmail,
                    data: $httpParamSerializerJQLike(user)
                });
            },
            updateUserMarketing: function(idOrEmail, userMarketing) {
                return $http({
                    method: 'PUT',
                    url: SERVICES_INT_URL + '/users/' + idOrEmail + '/marketing',
                    data: $httpParamSerializerJQLike(userMarketing)
                });
            },
            updateUserAddress: function(idOrEmail, userAddress) {
                return $http({
                    method: 'PUT',
                    url: SERVICES_INT_URL + '/users/' + idOrEmail + '/addresses/0',
                    data: $httpParamSerializerJQLike(userAddress)
                });
            },
            updateUserPhone: function(idOrEmail, userPhone) {
                return $http({
                    method: 'PUT',
                    url: SERVICES_INT_URL + '/users/' + idOrEmail + '/phones/0',
                    data: $httpParamSerializerJQLike(userPhone)
                });
            },
            deleteUser: function(idOrEmail) {
                return $http({
                    method: 'DELETE',
                    url: SERVICES_INT_URL + '/users/' + idOrEmail
                });
            },
            updateUserStatus: function(idOrEmail, status) {
                return $http({
                    method: 'PUT',
                    url: SERVICES_INT_URL + '/users/' + idOrEmail + '/status',
                    data: $httpParamSerializerJQLike({status: status})
                });
            },
            setDormantUsers: function(json, chunkNumber) {
                return $http({
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    url: SERVICES_INT_URL + '/users/dormant',
                    transformResponse: appendTransform($http.defaults.transformResponse, function(value) {
                        value.chunkNumber = chunkNumber;
                        if(value.errors) {
                            for(var i=0; i < value.errors.length; i++) {
                                value.errors[i].entry += chunkNumber * ADMIN_CONSTANTS.IMPORT_CHUNK_SIZE;
                            }
                        }

                        return value;
                    }),
                    data: json
                });
            },
            getUserAcceptTermsAndConditionsDate: function(idOrEmail) {
                return $http({
                    method: 'GET',
                    url: SERVICES_INT_URL + '/users/' + idOrEmail + '/termsandconditions'
                });
            },
            bulkDeleteUsers: function(json, chunkNumber) {
                return $http({
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    url: SERVICES_INT_URL + '/users/bulkDelete',
                    transformResponse: appendTransform($http.defaults.transformResponse, function(value) {
                        value.chunkNumber = chunkNumber;
                        if(value.errors) {
                            for(var i=0; i < value.errors.length; i++) {
                                value.errors[i].entry += chunkNumber * ADMIN_CONSTANTS.IMPORT_CHUNK_SIZE;
                            }
                        }

                        return value;
                    }),
                    data: json
                });
            },
            bulkDeleteUsersByDomain: function(domain) {
                return $http({
                    method: 'DELETE',
                    url: SERVICES_INT_URL + '/users/bulkDeleteDomain/' + domain
                });
            },
            getUserOktaDetails: function(oktaId) {
                return $http({
                    method: 'GET',
                    url: SERVICES_INT_URL + '/users/okta/' + oktaId
                });
            },
            getMfaRequired: function(idOrEmail) {
                return $http({
                    method: 'GET',
                    url: SERVICES_INT_URL + '/users/' + idOrEmail + '/mfaRequired'
                });
            },
            setMfaRequired: function(idOrEmail, mfaRequired) {
                return $http({
                    method: 'PUT',
                    url: SERVICES_INT_URL + '/users/' + idOrEmail + '/mfaRequired',
                    data: $httpParamSerializerJQLike({mfaRequired: mfaRequired})
                });
            }
        },
        emailPreferences: {
            optout: function(email) {
                return $http({
                    method: 'DELETE',
                    url: SERVICES_INT_URL + '/emailpreferences/' + encodeURIComponent(email) + "/optout"
                });
            },
            optin: function(email, sendEmail) {
                var request = {sendEmail: sendEmail};
                return $http({
                    method: 'PUT',
                    url: SERVICES_INT_URL + '/emailpreferences/' + encodeURIComponent(email) + "/optin",
                    data: $httpParamSerializerJQLike(request)
                });
            },
            checkOptinStatus: function(email) {
                return $http({
                    method: 'GET',
                    url: SERVICES_INT_URL + '/emailpreferences/' + encodeURIComponent(email) + "/optin/status"
                });
            },
            setEmailPreferences: function(json, chunkNumber) {
                return $http({
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    url: SERVICES_INT_URL + '/emailpreferences',
                    transformResponse: appendTransform($http.defaults.transformResponse, function(value) {
                        value.chunkNumber = chunkNumber;
                        if(value.errors) {
                            for(var i=0; i < value.errors.length; i++) {
                                value.errors[i].entry += chunkNumber * ADMIN_CONSTANTS.IMPORT_CHUNK_SIZE;
                            }
                        }

                        return value;
                    }),
                    data: json
                });
            },
            deleteEmailPreferences: function(email) {
                return $http({
                    method: 'DELETE',
                    url: SERVICES_INT_URL + '/emailpreferences/' + encodeURIComponent(email)
                });
            }
        },
        subscriptions: {

            uploadBulkSubscribe: function(json, chunkNumber) {
                return $http({
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    url: SERVICES_INT_URL + '/subscriber/subscribers',
                    transformResponse: appendTransform($http.defaults.transformResponse, function(value) {
                        value.chunkNumber = chunkNumber;
                        if(value.errors) {
                            for(var i=0; i < value.errors.length; i++) {
                                value.errors[i].entry += chunkNumber * ADMIN_CONSTANTS.IMPORT_CHUNK_SIZE;
                            }
                        }

                        return value;
                    }),
                    data: json
                });
            },
            uploadBulkUnsubscribe: function(json, chunkNumber) {
                return $http({
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    url: SERVICES_INT_URL + '/subscriber/unsubscribers',
                    transformResponse: appendTransform($http.defaults.transformResponse, function(value) {
                        value.chunkNumber = chunkNumber;
                        if(value.errors) {
                            for(var i=0; i < value.errors.length; i++) {
                                value.errors[i].entry += chunkNumber * ADMIN_CONSTANTS.IMPORT_CHUNK_SIZE;
                            }
                        }

                        return value;
                    }),
                    data: json
                });
            },
            getUserSubscriptions: function(profileId) {
                return $http({
                    method: 'GET',
                    url: SERVICES_INT_URL + '/subscriptions/user/' + profileId
                });
            },
            getAllSubscriptions: function() {
                return $http({
                    method: 'GET',
                    url: SERVICES_INT_URL + '/subscriptions'
                });
            },
            updateSubscription: function(subscriptionId, subscription) {
                return $http({
                    method: 'PUT',
                    url: SERVICES_INT_URL + '/subscriptions/' + subscriptionId,
                    data: $httpParamSerializerJQLike(subscription)
                });
            },
            getSubscription: function(subscriptionId) {
                return $http({
                    method: 'GET',
                    url: SERVICES_INT_URL + '/subscriptions/' + subscriptionId
                });
            },
            createSubscription: function(subscription) {
                return $http({
                    method: 'POST',
                    url: SERVICES_INT_URL + '/subscriptions',
                    data: $httpParamSerializerJQLike(subscription)
                });
            },
            getSubscriptionInstances: function(subscriptionId) {
                return $http({
                    method: 'GET',
                    url: SERVICES_INT_URL + '/subscriptions/' + subscriptionId + '/instances'
                });
            },
            getSubscriptionInstance: function(subscriptionInstanceId) {
                return $http({
                    method: 'GET',
                    url: SERVICES_INT_URL + '/subscriptions/instances/' + subscriptionInstanceId
                });
            },
            createSubscriptionInstance: function(subscriptionId, subscriptionFilter) {
                return $http({
                    method: 'POST',
                    url: SERVICES_INT_URL + '/subscriptions/' + subscriptionId + '/instances/',
                    data: $httpParamSerializerJQLike(subscriptionFilter)
                });
            },
            updateSubscriptionInstance: function(subscriptionInstanceId, subscriptionInstance) {
                return $http({
                    method: 'PUT',
                    url: SERVICES_INT_URL + '/subscriptions/instances/' + subscriptionInstanceId,
                    data: $httpParamSerializerJQLike(subscriptionInstance)
                });
            },
            deleteSubscriptionInstance: function(subscriptionInstanceId) {
                return $http({
                    method: 'DELETE',
                    url: SERVICES_INT_URL + '/subscriptions/instances/' + subscriptionInstanceId
                });
            },

            addUserSubscription: function(subscriptionId, profileId) {
                return $http({
                    method: 'PUT',
                    url: SERVICES_INT_URL + '/subscriptions/' + subscriptionId + '/user/' + profileId
                });
            },
            removeUserSubscription: function(subsciptionId, profileId) {
                return $http({
                    method: 'DELETE',
                    url: SERVICES_INT_URL + '/subscriptions/' + subsciptionId + '/user/' + profileId
                });
            }
        },
        groups: {
            search: function(groupCnPartial) {
                return $http({
                    method: 'GET',
                    url:  SERVICES_INT_URL + '/groups?commonName=' + encodeURIComponent(groupCnPartial)
                });
            },
            get: function(groupCn) {
                return $http({
                    method: 'GET',
                    url:  SERVICES_INT_URL + '/groups/' +encodeURIComponent(groupCn)
                });
            },
            create: function(groupCn) {
                return $http({
                    method: 'PUT',
                    url:  SERVICES_INT_URL + '/groups/' + encodeURIComponent(groupCn)
                });
            },
            removeMember: function(groupCn,userDN) {

                userDN = userDN.toLowerCase().trim();
                // parse cn from dn
                var userCn = userDN.substring(userDN.indexOf("cn=") + 3);
                userCn = userCn.substring(0,userCn.indexOf(",")).trim();

                return $http({
                    method: 'DELETE',
                    url: SERVICES_INT_URL +  '/groups/' + encodeURIComponent(groupCn) + "/" + userCn
                });
            },
            addMember: function(groupCn,userDN) {

                userDN = userDN.toLowerCase().trim();
                // parse cn from dn
                var userCn = userDN.substring(userDN.indexOf("cn=") + 3);
                userCn = userCn.substring(0,userCn.indexOf(",")).trim();

                return $http({
                    method: 'PUT',
                    url: SERVICES_INT_URL +  '/groups/' + encodeURIComponent(groupCn) + "/" + userCn
                });
            }
        },
        companies: {
            get: function(name, searchBy) {
                var url = SERVICES_INT_URL + '/companies/' + name;
                if(searchBy) {
                    url = url + '?companySearchBy=' + searchBy;
                }
                return $http({
                    method: 'GET',
                    url: url
                });
            },
            getById: function(id) {
                return $http({
                    method: 'GET',
                    url: SERVICES_INT_URL + '/companies/id/' + id
                });
            },
            create: function(company) {
                return $http({
                    method: 'POST',
                    url: SERVICES_INT_URL + '/companies',
                    data: $httpParamSerializerJQLike(company)
                });
            },
            update: function(id, name, notes) {
                return $http({
                    method: 'PUT',
                    url: SERVICES_INT_URL + '/companies/' + id,
                    data: $httpParamSerializerJQLike({'companyName': name, 'companyNotes':notes})
                });
            },
            domains:{
                groups: {
                    get: function() {
                        return $http({
                            method: 'GET',
                            url: SERVICES_INT_URL + '/companies/domains/groups'
                        });
                    }
                },
                create: function(name, companyId, groupIds) {
                    return $http({
                        method: 'POST',
                        url: SERVICES_INT_URL + '/companies/' + encodeURIComponent(companyId) + '/domains/',
                        data: $httpParamSerializerJQLike({'groupId': groupIds, 'companyDomainName':name}).replace(/%5B%5D/g, '')
                    });
                },
                remove: function(id) {
                    return $http({
                        method: 'DELETE',
                        url: SERVICES_INT_URL + '/companies/domains/' + id
                    });
                },
                update: function(id, name, groupIds) {
                    return $http({
                        method: 'PUT',
                        url: SERVICES_INT_URL + '/companies/domains/' + encodeURIComponent(id),
                        data: $httpParamSerializerJQLike({'groupId': groupIds, 'companyDomainName':name}).replace(/%5B%5D/g, '')
                    });
                }
            }
        },
        locales:{
            getLocales: function() {
                return $http({
                    method: 'GET',
                    url: SERVICES_INT_URL + '/locales'
                });
            },
            getLocale: function(id) {
                var url = SERVICES_INT_URL + '/locales/' + id;
                return $http({
                    method: 'GET',
                    url: url
                });
            },
            update: function(id, code,name,display) {
                var url = SERVICES_INT_URL + '/locales/' + id;
                return $http({
                    method: 'PUT',
                    url: url,
                    data: $httpParamSerializerJQLike({'code': code, 'name': name, 'display': display})
                });
            },
            create: function(code,name,display) {

                if ( !display ) {
                    display = 'false';
                }

                var url = SERVICES_INT_URL + '/locales';
                return $http({
                    method: 'POST',
                    url: url,
                    data: $httpParamSerializerJQLike({'code': code, 'name': name, 'display': display})
                });
            }
        },
        translations:{
            getClassificationsStats: function(localeCode) {
                var url = SERVICES_INT_URL + '/translations/classifications?localeCode=' + localeCode;
                return $http({
                    method: 'GET',
                    url: url
                });
            },
            downloadTranslationsForLocaleAndClassification: function(localeCode, propertyClassification) {
                var url = SERVICES_INT_URL + '/translations/downloads?localeCode=' + localeCode + "&propertyClassification=" + propertyClassification;
                return $http({
                    method: 'GET',
                    url: url,
                    headers:{'Accept': 'application/vnd.sas.profile-v3+text'},
                    responseType:'arraybuffer' // needed for download, see https://stackoverflow.com/questions/38260859/download-the-byte-array-as-a-original-file-in-angularjs-from-server
                });
            },
            getVersions : function() {
                var url = SERVICES_INT_URL + '/translations/versions';
                return $http({
                    method: 'GET',
                    url: url
                });
            },
            downloadTranslationsForBackupVersion : function(version) {
                var url = SERVICES_INT_URL + '/translations/versions/' + version;
                return $http({
                    method: 'GET',
                    url: url,
                    // headers:{'Accept': 'application/vnd.sas.profile-v3+text'},
                    responseType:'arraybuffer' // needed for download, see https://stackoverflow.com/questions/38260859/download-the-byte-array-as-a-original-file-in-angularjs-from-server
                });
            },
            calculateDiffForRevert : function(version) {
                var url = SERVICES_INT_URL + '/translations/versions/' + version + '/diff';
                return $http({
                    method: 'GET',
                    url: url
                });
            },
            calculateDiffForRemote : function(remote) {
                var url = SERVICES_INT_URL + '/translations/remote/' + remote + '/diff';
                return $http({
                    method: 'GET',
                    url: url
                });
            },
            calculateDiffForImport : function(locale, file) {
                var url = SERVICES_INT_URL + '/translations/import/' + locale + '/diff';

                var fd = new FormData();
                fd.append('translationImportFile', file);

                return $http({
                    method: 'PUT',
                    headers: {
                        'Content-Type': undefined
                    },
                    url: url,
                    data: fd
                });
            },
            doRevert : function(version) {
                var url = SERVICES_INT_URL + '/translations/versions/' + version;
                return $http({
                    method: 'PUT',
                    url: url
                });
            },
            doOverwriteWithRemote : function(remote) {
                var url = SERVICES_INT_URL + '/translations/remote/' + remote;
                return $http({
                    method: 'PUT',
                    url: url
                });
            },
            doOverwriteWithImport : function(locale, file) {
                var url = SERVICES_INT_URL + '/translations/import/' + locale;
                var fd = new FormData();
                fd.append('translationImportFile', file);

                return $http({
                    method: 'PUT',
                    headers: {
                        'Content-Type': undefined
                    },
                    url: url,
                    data: fd
                });
            },
            doBackup : function(backupReason) {
                var url = SERVICES_INT_URL + '/translations/versions';
                //   var object = {};
                //  object.backupReason = backupReason;
                return $http({
                    method: 'POST',
                    url: url,
                    data: $httpParamSerializerJQLike( {backupReason: backupReason} )
                });
            },
            getProperties : function() {
                var url = SERVICES_INT_URL + '/translations/properties';
                return $http({
                    method: 'GET',
                    url: url
                });
            },
            deleteSingleTranslation : function(name,locale) {
                return $http({
                    method: 'DELETE',
                    url: SERVICES_INT_URL + '/translations/properties/' + (locale == '' ? 'ROOT' : locale ) +  '/' + encodeURIComponent(name)
                });
            },
            createOrUpdateTranslation : function(name,locale,data) {
                var newvalue = {translationValue: data};
                return $http({
                    method: 'PUT',
                    url: SERVICES_INT_URL + '/translations/properties/' + (locale == '' ? 'ROOT' : locale )+ '/' + encodeURIComponent(name),
                    data: $httpParamSerializerJQLike(newvalue)
                });
            }

        },
        utilities:{
            getServiceStatus: function(basePaths, version) {
                var serviceBasePaths = basePaths.split(',');
                return $http({
                    method: 'PUT',
                    url: SERVICES_INT_URL + '/utilities/serviceStatus',
                    data: $httpParamSerializerJQLike({basePath: serviceBasePaths[0], iaBasePath: serviceBasePaths[1], version: version})
                });
            },
            getEnvironments: function(basePaths, version) {
                return $http({
                    method: 'GET',
                    url: SERVICES_INT_URL + '/utilities/environments'
                });
            },

        },
        environment:{
            getProfileInternalSettings: function() {
                return $http({
                    method: 'GET',
                    url: SERVICES_INT_URL + '/environment/profile/internalSetting'
                });
            },
            createProfileInternalSettings: function(name, value) {
                return $http({
                    method: 'POST',
                    url: SERVICES_INT_URL + '/environment/profile/internalSetting/',
                    data: $httpParamSerializerJQLike({'name': name, 'value': value})
                });
            },
            updateProfileInternalSettings: function(name, value) {
                return $http({
                    method: 'PUT',
                    url: SERVICES_INT_URL + '/environment/profile/internalSetting/' + name,
                    data: $httpParamSerializerJQLike({'value': value})
                });
            },
            getOptinInternalSettings: function() {
                return $http({
                    method: 'GET',
                    url: SERVICES_INT_URL + '/environment/optin/internalSetting'
                });
            },
            updateOptinInternalSettings: function(name, value) {
                return $http({
                    method: 'PUT',
                    url: SERVICES_INT_URL + '/environment/optin/internalSetting/' + name,
                    data: $httpParamSerializerJQLike({'value': value})
                });
            }
        },
        requests:{
            groupMemberReport: function(groupCN) {
                return $http({
                    method: 'POST',
                    url: SERVICES_INT_URL + '/requests/reports/groups/'+ encodeURIComponent(groupCN) + '/membership'
                });
            }
        },
        constants: {
            marketing : {
                getAffiliations: function() {
                    return $http({
                        method: 'GET',
                        url: SERVICES_INT_URL + '/constants/affiliations'
                    });
                },
                getIndustries: function() {
                    return $http({
                        method: 'GET',
                        url: SERVICES_INT_URL + '/constants/industries'
                    });
                },
                getJobFunctions: function() {
                    return $http({
                        method: 'GET',
                        url: SERVICES_INT_URL + '/constants/jobFunctions'
                    });
                },
                getExpertise: function() {
                    return $http({
                        method: 'GET',
                        url: SERVICES_INT_URL + '/constants/expertise'
                    });
                }
            },
            getCountries: function() {
                return $http({
                    method: 'GET',
                    url: SERVICES_INT_URL + '/constants/countries'
                });
            },
            getFederatedDomains: function() {
                return $http({
                    method: 'GET',
                    url: SERVICES_INT_URL + '/constants/federatedDomains'
                });
            },
            getLanguages: function() {
                return $http({
                    method: 'GET',
                    url: SERVICES_INT_URL + '/constants/languages'
                });
            }
        },
        applications :{
            getAllKeys: function() {
                return $http({
                    method: 'GET',
                    url: SERVICES_INT_URL + '/applications'
                });
            },
            getAllPermissions: function() {
                return $http({
                    method: 'GET',
                    url: SERVICES_INT_URL + '/applications/permissions'
                });
            },
            getSingleAppPermissions: function(id) {
                return $http({
                    method: 'GET',
                    url: SERVICES_INT_URL + '/applications/' + id + '/permissions'
                });
            },
            getSingleApp: function(id) {
                return $http({
                    method: 'GET',
                    url: SERVICES_INT_URL + '/applications/' + id
                });
            },
            setApplicationEnabled: function(id,enableFlag) {
                return $http({
                    method: 'PUT',
                    url: SERVICES_INT_URL + '/applications/' + id + '/enabled',
                    data: $httpParamSerializerJQLike({enabled: enableFlag})
                });
            },
            createApplication: function(app) {
                return $http({
                    method: 'POST',
                    url: SERVICES_INT_URL + '/applications',
                    data: $httpParamSerializerJQLike(app)
                });
            },
            updateApplication: function(app) {
                return $http({
                    method: 'PUT',
                    url: SERVICES_INT_URL + '/applications/' + app.id,
                    data: $httpParamSerializerJQLike(app)
                });
            },
            updatePermissions: function(id,perms) {
                return $http({
                    method: 'PUT',
                    url: SERVICES_INT_URL + '/applications/' + id + '/permissions',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: {permissions: perms}
                });
            },
            propagateApplication: function(id,toThycotic,toOtherEnvironments,includePermissions) {
                return $http({
                    method: 'PUT',
                    url: SERVICES_INT_URL + '/applications/' + id + '/propagate',
                    data: $httpParamSerializerJQLike({toThycotic: toThycotic, toOtherEnvironments: toOtherEnvironments, includePermissions: includePermissions})
                });
            }
        },
        accessChecks:  {
            getAccessRestrictionCount: function() {
                return $http({
                    method: 'GET',
                    url: SERVICES_INT_URL + "/accesscheck/restrictions/count"
                });
            },
            getAllAccessRestrictions: function(index) {
                return $http({
                    method: 'GET',
                    url: SERVICES_INT_URL + "/accesscheck/restrictions/" + index
                });
            },
            getUserAccessRestrictions: function(idOrEmail) {
                return $http({
                    method: 'GET',
                    url: SERVICES_INT_URL + "/accesscheck/" + idOrEmail
                });
            },
            allowUserAccessRestriction: function(idOrEmail, sendEmail) {
                return $http({
                    method: 'PUT',
                    url: SERVICES_INT_URL + "/accesscheck/allow/" + idOrEmail + "?sendEmail=" + sendEmail,
                    data: $httpParamSerializerJQLike(sendEmail)
                });
            },
            denyUserAccessRestriction: function(idOrEmail, sendEmail) {
                return $http({
                    method: 'PUT',
                    url: SERVICES_INT_URL + "/accesscheck/deny/" + idOrEmail + "?sendEmail=" + sendEmail,
                    data: $httpParamSerializerJQLike(sendEmail)
                });
            },
            getUserAccessDenials: function(idOrEmail) {
                return $http({
                    method: 'GET',
                    url: SERVICES_INT_URL + "/accesscheck/denials/" + idOrEmail
                });
            },
            getAccessStatus: function(idOrEmail) {
                return $http({
                    method: 'GET',
                    url: SERVICES_INT_URL + "/accesscheck/status/" + idOrEmail
                })
            }
        }
    };
}]);

app.factory('AdminUtils', ["$sce", function ($sce) {
    'use strict';

    return {
        /**
         * Trusts server output as HTML code.
         *
         * @param htmlCode the HTML code to trust
         * @returns trusted HTML code
         */
        trustAsHtml: function (htmlCode) {
            return $sce.trustAsHtml(htmlCode);
        },
        /**
         * Converts a camel-case string into a title format.
         *
         * @param string the string to convert
         * @returns the titlefied string
         */
        titlefyString: function(string) {
            return string
            // insert a space before all caps
                .replace(/([A-Z])/g, ' $1')
                // uppercase the first character
                .replace(/^./, function(str){ return str.toUpperCase(); })
                .replace('D N', 'DN')
                .replace('S A M', 'SAM')
        }
    };
}]);
app.factory('PermissionsService', ["ADMIN_ACCESS_GROUPS", function(ADMIN_ACCESS_GROUPS) {
    'use strict';

	var permissions = null;

	return {
		getPermissions: function() {
			if(permissions===null){
				permissions={};
				var roles = window.sessionStorage.getItem(oauthConfig.ROLES);
				if(roles){
					permissions.hasCompanyRole = roles.includes(ADMIN_ACCESS_GROUPS.COMPANY) ||
						roles.includes(ADMIN_ACCESS_GROUPS.SUPERUSER);

					permissions.hasNewsletterRole = roles.includes(ADMIN_ACCESS_GROUPS.NEWSLETTER) ||
						roles.includes(ADMIN_ACCESS_GROUPS.SUPERUSER);

					permissions.hasProfileViewRole = roles.includes(ADMIN_ACCESS_GROUPS.COMPANY) ||
						roles.includes(ADMIN_ACCESS_GROUPS.NEWSLETTER) ||
						roles.includes(ADMIN_ACCESS_GROUPS.PROFILE_VIEW) ||
						roles.includes(ADMIN_ACCESS_GROUPS.PROFILE_EDIT) ||
						roles.includes(ADMIN_ACCESS_GROUPS.PROFILE_DELETE) ||
						roles.includes(ADMIN_ACCESS_GROUPS.ACCESS_CONTROL) ||
						roles.includes(ADMIN_ACCESS_GROUPS.SUPERUSER) ||
						roles.includes(ADMIN_ACCESS_GROUPS.PROFILE_CONFIGURE) ||
						roles.includes(ADMIN_ACCESS_GROUPS.TRANSLATION_EDIT);

					permissions.hasProfileEditRole = roles.includes(ADMIN_ACCESS_GROUPS.PROFILE_EDIT) ||
						roles.includes(ADMIN_ACCESS_GROUPS.SUPERUSER);

					permissions.hasProfileDeleteRole = roles.includes(ADMIN_ACCESS_GROUPS.PROFILE_DELETE) ||
						roles.includes(ADMIN_ACCESS_GROUPS.SUPERUSER);

					permissions.hasAccessControlRole = roles.includes(ADMIN_ACCESS_GROUPS.ACCESS_CONTROL) ||
						roles.includes(ADMIN_ACCESS_GROUPS.SUPERUSER);

					permissions.hasSuperUserRole = roles.includes(ADMIN_ACCESS_GROUPS.SUPERUSER);

					permissions.hasProfileConfigureRole = roles.includes(ADMIN_ACCESS_GROUPS.PROFILE_CONFIGURE) ||
						roles.includes(ADMIN_ACCESS_GROUPS.SUPERUSER);

					permissions.hasTranslationEditRole = roles.includes(ADMIN_ACCESS_GROUPS.TRANSLATION_EDIT) ||
						roles.includes(ADMIN_ACCESS_GROUPS.SUPERUSER);
				}
			}
			return permissions;
		}
	};
}]);

app.factory('AdminErrorHandler', ["$window", "ADMIN_ERRORS", "$location", "BASE_URL", "$rootScope", function ($window, ADMIN_ERRORS, $location, BASE_URL, $rootScope) {
    'use strict';

    return {
        /**
         * Handles 401 and 403 errors
         * 401 means the user is not authenticated or the authentication is not valid/expired/etc.
         * 403 means the user is authenticated but is not authorized to perform the requested operation.
         *
         * @param data the response data
         * @param status the response status
         */
        handlePermissionsErrors: function(data, status) {
            if(status === ADMIN_ERRORS.UNAUTHORIZED_ERROR) {
                if(data && data.item && data.item === "appKey") {
                    // If the 401 was due to an appkey problem, there's nothing that can be done about that, so error out
                    this.goToErrorPage();
                } else {
                    // If the 401 was not due to an appkey problem, assume it is a JWT problem and reauthenticate
                    // The JWT 401 responses have no body and therefore we cannot check the body to see the exact error
                    window.sessionStorage.removeItem(oauthConfig.JWT);
                    window.sessionStorage.removeItem(oauthConfig.ROLES);
                    this.handleLogin();
                }
            } else if(status === ADMIN_ERRORS.FORBIDDEN_ERROR) {
                // 403/Forbidden always goes to the error page regardless of whether it is appkey or bearer token-related.
                // Users should never encounter either because the appkey is static and the UI doesn't let users see
                // operations for which they don't have privileges.
                this.goToErrorPage();
            }
        },

        /**
         * Handles server error, e.g. when the server is down.
         *
         * @param data the response data
         * @param status the response status
         */
        handleServerError: function(data, status) {
            var serverError = false;

            try {
                angular.fromJson(data);
            }
            catch(e) {
                serverError = true;
            }

            if(!status || status === ADMIN_ERRORS.INTERNAL_SERVER_ERROR
                || (data && data.item && data.item == 'http')) {
                serverError = true;
            }

            if(serverError) {
                this.goToErrorPage();
            }
        },

        /**
         * Handles all application-level errors. This function is automatically applied
         * to failed responses by the AdminClientInterceptor.
         *
         * @param data the response data
         * @param status the response status
         */
        handleApplicationErrors: function(data, status) {
            this.handleServerError(data, status);
            this.handlePermissionsErrors(data, status);
            this.handleServiceUnavailableError(data, status);
        },

        /**
         * Handles invalid parameters. This function is page-specific.
         *
         * @param data the response data
         * @param status the response status
         * @param pageErrors the list of errors for the page
         * @param pageForm the form controller for the page
         */
        handleInvalidParameterError: function(data, status, pageErrors, pageForm) {
            if(status === ADMIN_ERRORS.INVALID_PARAMETER_ERROR) {
                this.handleFieldError(data, status, pageErrors, pageForm);
            }
        },

        /**
         * Handles a backend service being unavailable.
         *
         * @param data the response data
         * @param status the response status
         */
        handleServiceUnavailableError: function(data, status) {
            if(status === ADMIN_ERRORS.SERVICE_UNAVAILABLE_ERROR) {
                this.goToErrorPage();
            }
        },

        handleOptinServiceUnavailableError: function(data, status, pageErrors, pageForm) {
            if(status === ADMIN_ERRORS.SERVICE_UNAVAILABLE_ERROR) {
                if(typeof data.item !== undefined){
                    pageErrors.emailAddress = data;
                    pageForm.emailAddress.$error.server = data.message;
                    pageForm.emailAddress.$setValidity('server',false);
                }else{
                    this.goToErrorPage();
                }
            }
        },

        /**
         * Handles any error pertaining to a specific field on the page. This function is page-specific.
         *
         * @param data the response data
         * @param status the response status
         * @param pageErrors the list of errors for the page
         * @param pageForm the form controller for the page
         */
        handleFieldError: function(data, status, pageErrors, pageForm) {

            var setParameterInvalid = function(pageErrors, pageForm, data) {
                try {
                    pageErrors[data.item] = data;
                    pageForm[data.item].$error.server = data.message;
                    pageForm[data.item].$setValidity('server',false);
                } catch (error) {
                    // Om nom nom (allow error processing to continue even if form fields are missing)
                }
            };

            if(angular.isArray(data))
            {
                for (var i = 0; i < data.length; i++) {
                    setParameterInvalid(pageErrors, pageForm, data[i]);
                }
            }
            else{
                setParameterInvalid(pageErrors, pageForm, data);
            }
        },

        /**
         * Handles a conflict error. This function is page-specific.
         *
         * @param data the response data
         * @param status the response status
         * @param pageErrors the list of errors for the page
         * @param pageForm the form controller for the page
         */
        handleConflictError: function(data, status, pageErrors, pageForm) {
            if(status === ADMIN_ERRORS.CONFLICT_ERROR) {
                try {
	                pageForm[data.item].$setValidity('server',false);
	                pageErrors[data.item] = data;
                } catch (error) {
	                // Om nom nom (allow error processing to continue even if form fields are missing)
                }
            }
        },

        /**
         * Handles a not found error. This function is page-specific.
         *
         * @param data the response data
         * @param status the response status
         * @param pageErrors the list of errors for the page
         * @param pageForm the form controller for the page
         */
        handleNotFoundError: function(data, status, pageErrors, pageForm) {
            if(status === ADMIN_ERRORS.NOT_FOUND_ERROR) {
                try {
                    pageForm[data.item].$setValidity('server', false);
                    pageErrors[data.item] = data;
                } catch (error) {
	                // Om nom nom (allow error processing to continue even if form fields are missing)
                }
            }
        },

        /**
         * Handles input errors from user. This function is page specific and should
         * be called from a page controller.
         *
         * @param data the response data
         * @param status the response status
         * @param pageErrors the list of errors for the page
         * @param pageForm the form controller for the page
         */
        handleInputErrors: function(data, status, pageErrors, pageForm) {
            this.handleInvalidParameterError(data, status, pageErrors, pageForm);
            this.handleConflictError(data, status, pageErrors, pageForm);
            this.handleNotFoundError(data, status, pageErrors, pageForm);
        },

        /**
         * Removes a server error from a page.
         *
         * @param pageForm the page form to remove the errors from
         * @param pageFormControl the page form control to remove the errors from
         * @param pageErrors the list of page errors
         */
        removePageError: function(pageForm, pageFormControl, pageErrors) {
            try {
                pageForm[pageFormControl].$setValidity('server', true);
                delete pageErrors[pageFormControl];
            } catch (error) {
	            // Om nom nom (allow error processing to continue even if form fields are missing)
            }
        },

        /**
         * Removes multiple server errors from a page.
         *
         * @param pageForm the page form to remove the errors from
         * @param pageFormControls the list of page form controls to remove the errors from
         * @param pageErrors the list of page errors
         */
        removePageErrors: function(pageForm, pageFormControls, pageErrors) {
            if(angular.isArray(pageFormControls)) {
                pageFormControls.forEach(function(pageFormControl){
                    this.removePageError(pageForm, pageFormControl, pageErrors);
                }, this);
            }
            else {
                this.removePageError(pageForm, pageFormControls, pageErrors);
            }
        },

        /**
         * Redirects to the error page.
         */
        goToErrorPage: function() {
            $rootScope.globalError = true;

            $location.path('/error');
        },

        /**
         * Redirects to the permission denied page.
         */
        goToPermissionDeniedPage: function() {
            $rootScope.globalError = true;

            $location.path('/permissiondenied');
        },

        /**
         * Uses MSAL to redirect to Azure for JWT
         */
        handleLogin: function() {
            if(!window.sessionStorage.getItem(oauthConfig.LOGIN_INDICATOR)){
                window.sessionStorage.setItem(oauthConfig.LOGIN_INDICATOR, 'true');
                msalAuth.loginRedirect({});
            }
        },

        /**
         * Checks if the error is a non-AA-related 400-series error
         */
        isClientError: function(status) {
            return status === ADMIN_ERRORS.INVALID_PARAMETER_ERROR ||
                status === ADMIN_ERRORS.CONFLICT_ERROR ||
                status === ADMIN_ERRORS.NOT_FOUND_ERROR;
        }
    };
}]);

app.factory('AdminClientInterceptor', ["$rootScope", "ADMIN_ERRORS", "AdminErrorHandler", "$q", function($rootScope, ADMIN_ERRORS, AdminErrorHandler, $q) {
    'use strict';

	var excludedUrls = [
		'/profile/services/ia/applications',
		'/profile/services/ia/accesscheck',
		'/profile/services/ia/emailpreferences'
	];

	return {
		responseError: function(response) {
			var excluded = false;

			for (var i = 0; i < excludedUrls.length; i++) {
				if (response.config.url.contains(excludedUrls[i])) {
					excluded = true;

					break;
				}
			}

			if(!excluded || response.status === ADMIN_ERRORS.UNAUTHORIZED_ERROR) {
				AdminErrorHandler.handleApplicationErrors(response.data, response.status);
			}

			return $q.reject(response);
		}
	};
}]);
app.factory('QueryParamParser', ["$http", "$location", function ($http, $location) {
    'use strict';

    var self = {
        getQueryParams: function() {
            var params = $location.search();

            if (!params) {
                return {};
            }

            return params;
        }
    };

    return self;
}]);
app.directive('adminNavigation', function(){
    'use strict';

    return {
        restrict: 'E',
        templateUrl: 'partials/directives/AdminNavigation.html',
        replace: true,
        controller: ["AdminClient", "$scope", "PermissionsService", "$route", "IS_PRODUCTION", "$location", "OKTA_ORG_URL", function(AdminClient, $scope, PermissionsService, $route, IS_PRODUCTION, $location, OKTA_ORG_URL) {
            $scope.production = IS_PRODUCTION;
            $scope.oktaURL = OKTA_ORG_URL;

            $scope.permissions=PermissionsService.getPermissions();
            $scope.finished = true;

            $scope.path = function(path) {
                $location.url(path);
                $route.reload();
            };
        }]
    };
});

app.directive("fileread", [function () {
    return {
        restrict: 'A',
        scope: {
            fileread: "="
        },
        link: function (scope, element, attributes) {
            element.bind("change", function (changeEvent) {
                var reader = new FileReader();
                reader.onload = function (loadEvent) {
                    scope.$apply(function () {
                        scope.fileread = loadEvent.target.result;
                    });
                };
                reader.readAsText(changeEvent.target.files[0]);
            });
        }
    }
}]);
app.directive('profileAdminUpload', function(){
    return {
        restrict: 'E',
        templateUrl: 'partials/directives/ProfileAdminUpload.html',
        scope: {
            info: '=',
            response: '=',
            aggregator: '=',
            errorGridData: '=',
            adminClientFunction: '=',
            duration: '=?',
            expectedFields: '=?',
	        keySpellingCorrections: '=?'
        },
        controller: ["$scope", "$q", "ADMIN_CONSTANTS", function($scope, $q, ADMIN_CONSTANTS) {
            $scope.mergeResponses = function() {
                for(var i=0; i < $scope.serviceResponses.length; i++) {
                    if(i === 0) {
                        $scope.response = $scope.serviceResponses[i];
                    }
                    else {
                        $scope.aggregator($scope.response, $scope.serviceResponses[i]);
                    }
                }
            };

            $scope.validateCSVFields = function (uploadedCSVFields) {
                var i,j;

                for (i = 0; i < $scope.expectedFields.length; i++) {
                    var found = false;
                    for (j = 0; j < uploadedCSVFields.length; j++) {
                        var fieldName = "" + uploadedCSVFields[j];
                        if($scope.keySpellingCorrections && $scope.keySpellingCorrections.hasOwnProperty(fieldName)) {
                            fieldName=$scope.keySpellingCorrections[fieldName];
                        }

                        if(fieldName.toUpperCase() === $scope.expectedFields[i].toUpperCase()){
                            found = true;
                            break;
                        }
                    }

                    if (!found) {
                        console.log("Can't find " + $scope.expectedFields[i]);
                        return false;
                    }
                }

                return true;
            };

            $scope.callUploadService = function(results) {
                var startDate = new Date();

                $scope.progress = 10;
                $scope.requests = [];
                $scope.serviceResponses = [];
                $scope.numberComplete = 0;

                var i,requestChunk;
                for (i=0; i < results.data.length; i += ADMIN_CONSTANTS.IMPORT_CHUNK_SIZE) {
                    requestChunk = results.data.slice(i,i+ADMIN_CONSTANTS.IMPORT_CHUNK_SIZE);

                    $scope.requests.push($scope.adminClientFunction(requestChunk, i/ADMIN_CONSTANTS.IMPORT_CHUNK_SIZE)
                        .then(function(response) { // Success
                                if(response.data.errors && response.data.errors[0] && response.data.errors[0].errorMessage
                                    && response.data.errors[0].errorMessage === 'Invalid action: null') {
                                    $scope.csvError();
                                }

                                if(!response.errors) {
                                    response.errors = [];
                                }

                                $scope.serviceResponses[response.data.chunkNumber] = response.data;
                                $scope.numberComplete++;
                                $scope.progress = Math.max(10, Math.floor($scope.numberComplete / $scope.requests.length * 100));
                            },
                            function(){ // Error
                                $scope.csvError();
                            }
                        ));
                }

                //no need to keep the file in memory
                delete $scope.csvFile;

                $q.all($scope.requests).then(function() {
                    $scope.mergeResponses();

                    $scope.errorGridData = $scope.response.errors;

                    var endDate = new Date();
                    $scope.duration = (endDate.getTime() - startDate.getTime()) / 1000;

                    if(!$scope.info.csvError){
                        $scope.info.finished = true;
                    }
                });
            };

            $scope.trimRow = function(data) {
                for(var key in data) {
                    try{
                        data[key.trim()] = data[key].trim();
                    }catch(err) {
                        $scope.csvError();
                    }

                    if(key !== key.trim()) {
                        delete data[key];
                    }
                }
            };

	        $scope.correctKeySpelling = function(data) {
	            if($scope.keySpellingCorrections) {
		            for(var key in data) {
			            if($scope.keySpellingCorrections.hasOwnProperty(key)) {
				            data[$scope.keySpellingCorrections[key]] = data[key];
				            delete data[key];
			            }
		            }
	            }
	        };

            $scope.upload = function() {
                $scope.info.csvError = false;
                $scope.processing = true;

                Papa.parse($scope.csvFile, {
                    header: true,
                    skipEmptyLines:true,
                    complete: function(results) {
                        if($scope.expectedFields) {
                            if($scope.validateCSVFields(results.meta.fields)) {
                                for(var i=0; i<results.data.length; i++) {
                                    $scope.trimRow(results.data[i]);
                                    $scope.correctKeySpelling(results.data[i]);
                                }
                                $scope.callUploadService(results);
                            } else {
                                $scope.csvError();
                            }
                        } else {
                            console.log("No expectedFields found; fix the upload page!");
                            $scope.csvError();
                        }
                    }
                });
            };

            $scope.csvError = function() {
                $scope.info.csvError = true;
                $scope.processing = false;
                delete $scope.csvFile;

                // Clear the file input
                angular.forEach(
                    angular.element("input[type='file']"),
                    function(inputElem) {
                        angular.element(inputElem).val(null);
                    });
            };
        }]
    };
});
app.directive('userAccessRestrictions', function(){
    'use strict';

    return {
        restrict: 'E',
        templateUrl: 'partials/directives/UserAccessRestrictions.html',
        replace: true,
        scope: {idOrEmail:'=idOrEmail'},
        controller: ["$scope", "AdminClient", "AdminErrorHandler", "ADMIN_ERRORS", "ADMIN_CONSTANTS", function($scope, AdminClient, AdminErrorHandler, ADMIN_ERRORS, ADMIN_CONSTANTS){
            $scope.confirmDialogBox = function() {
                var sendEmail;
                if (window.confirm("Do you wish to send an email notification to this customer?\n" +
                    "OK - completes action and sends email; Cancel completes action with no email)")) {
                    sendEmail = true;
                } else {
                    sendEmail = false;
                }
                return sendEmail;
            };

            $scope.allowAccessRestriction = function(idOrEmail) {
                var sendEmail = $scope.confirmDialogBox();
                AdminClient.accessChecks.allowUserAccessRestriction(idOrEmail, sendEmail).then(
                    function(){
                        $scope.getUserAccessRestrictions();
                    },
                    function(){
                        AdminErrorHandler.goToErrorPage();
                    }
                );
            };

            $scope.denyAccessRestriction = function(idOrEmail) {
                var sendEmail = $scope.confirmDialogBox();
                AdminClient.accessChecks.denyUserAccessRestriction(idOrEmail, sendEmail).then(
                    function(){
                        $scope.getUserAccessRestrictions();
                    },
                    function(){
                        AdminErrorHandler.goToErrorPage();
                    }
                );
            };

            $scope.getUserAccessRestrictions = function(){
	            $scope.serviceAccessDenied = false;
                $scope.serviceUnavailable = false;
                AdminClient.accessChecks.getUserAccessRestrictions($scope.idOrEmail).then(
                    function(response){
                        $scope.accessRestrictions = response.data;
                        $scope.pageReady = true;
                    },
                    function(error){
                        if(error.status === ADMIN_ERRORS.SERVICE_UNAVAILABLE_ERROR) {
                            $scope.accessRestrictions = null;
                            $scope.serviceUnavailable = true;
                            $scope.pageReady = true;
                        } else if(error.status === ADMIN_ERRORS.UNAUTHORIZED_ERROR || error.status === ADMIN_ERRORS.FORBIDDEN_ERROR) {
	                        $scope.accessRestrictions = null;
	                        $scope.serviceAccessDenied = true;
	                        $scope.pageReady = true;
                        } else {
                            AdminErrorHandler.goToErrorPage();
                        }

                    }
                );
            };

            $scope.setSelectedUserEmail = function() {
                var searchTerms;
                searchTerms = {
                    profileId:$scope.idOrEmail
                };
                AdminClient.users.getUsers(searchTerms).then(
                    function(response) {
                        $scope.selectedEmail = response.data[0].emailAddress;
                    },
                    function () {
                        AdminErrorHandler.goToErrorPage();
                    }
                );
            };

            $scope.ADMIN_CONSTANTS = ADMIN_CONSTANTS;

            if($scope.idOrEmail) {
                $scope.getUserAccessRestrictions();
                var term = $scope.idOrEmail.toString();
                if (term.indexOf("@") < 0) {
                    $scope.setSelectedUserEmail(term);
                }
                else {
                    $scope.selectedEmail = $scope.idOrEmail;
                }
            }

        }]
    };
});

app.directive('userAccessDenials', function(){
    'use strict';

    return {
        restrict: 'E',
        templateUrl: 'partials/directives/UserAccessDenials.html',
        replace: true,
        scope: {idOrEmail:'=idOrEmail'},
        controller: ["$scope", "AdminClient", "AdminErrorHandler", "ADMIN_ERRORS", "ADMIN_CONSTANTS", function($scope, AdminClient, AdminErrorHandler, ADMIN_ERRORS, ADMIN_CONSTANTS){

            $scope.getUserAccessDenials = function() {
                $scope.serviceAccessDenied = false;
                $scope.serviceUnavailable = false;
                AdminClient.accessChecks.getUserAccessDenials($scope.idOrEmail).then(
                    function(response){
                        if(response.data) {
                            $scope.accessDenials = response.data;
                            $scope.pageReady = true;
                        }
                    },
                    function(error){
                        if(error.status === ADMIN_ERRORS.SERVICE_UNAVAILABLE_ERROR) {
	                        $scope.accessDenials = null;
                            $scope.serviceUnavailable = true;
	                        $scope.pageReady = true;
                        } else if(error.status === ADMIN_ERRORS.UNAUTHORIZED_ERROR || error.status === ADMIN_ERRORS.FORBIDDEN_ERROR) {
	                        $scope.accessDenials = null;
	                        $scope.serviceAccessDenied = true;
	                        $scope.pageReady = true;
                        } else {
                            AdminErrorHandler.goToErrorPage();
                        }

                    }
                );
            };

            $scope.ADMIN_CONSTANTS = ADMIN_CONSTANTS;

            if($scope.idOrEmail) {
                $scope.getUserAccessDenials();
            }
        }]
    };
});

app.controller('CompanyAdminController', ["$scope", "AdminClient", "$location", "ADMIN_ERRORS", "$filter", "AdminErrorHandler", "PermissionsService", function($scope, AdminClient, $location, ADMIN_ERRORS, $filter, AdminErrorHandler, PermissionsService){
    'use strict';

    if(!PermissionsService.getPermissions().hasCompanyRole){
        AdminErrorHandler.goToPermissionDeniedPage();
    }

    $scope.searchCompaniesErrors = {};

    $scope.foundCompanies = [];
    $scope.foundCompaniesGridOptions = {
        columnDefs: [{field: 'id', width:'80', resizable:'true'},
            {field: 'name'},
            {field: 'createDate'},
            {field: 'notes'},
            {field: 'domains'}],
        enableGridMenu: true
    };

    $scope.searchCompany = {};

    $scope.searchCompanies = function() {
        AdminClient.companies.get($scope.searchCompany.companySearchTerm, $scope.searchCompany.companySearchBy)
            .then(function(response) { // Success
                    $scope.foundCompanies = response.data;
                    $scope.foundCompaniesGridOptions.data = $scope.foundCompanies;

                    $scope.foundCompaniesGridOptions.data.forEach(function updateRows(row) {
                        row.createDate = $filter('date')(new Date(row.createDate), 'medium', 'en_US');

                        row.domains = '';
                        row.companyDomains.forEach(function displayDomains(domain) {
                            if(row.domains === '') {
                                row.domains = row.domains + domain.domain;
                            }
                            else {
                                row.domains = row.domains + ', ' + domain.domain;
                            }
                        });
                    });
                },function(response) { // Error
                    AdminErrorHandler.handleInputErrors(response.data, response.status, $scope.searchCompaniesErrors, $scope.companySearchForm);
                    $scope.foundCompanies = [];
                    $scope.foundCompaniesGridOptions.data = $scope.foundCompanies;
                }
            );
    };

    $scope.changeSearchCompany = function(element) {
        if(element) {
            AdminErrorHandler.removePageErrors($scope.companySearchForm, element, $scope.searchCompaniesErrors);
        }
        else {
            AdminErrorHandler.removePageErrors($scope.companySearchForm, $scope.searchGroup, $scope.searchCompaniesErrors);
        }
    };
}]);
app.controller('UnlockAccountController', ["$scope", "AdminClient", "$location", "ADMIN_ERRORS", "$filter", "AdminErrorHandler", "PermissionsService", function($scope, AdminClient, $location, ADMIN_ERRORS, $filter, AdminErrorHandler, PermissionsService){
    'use strict';

    if(!PermissionsService.getPermissions().hasProfileEditRole){
        AdminErrorHandler.goToPermissionDeniedPage();
    }

    $scope.unlockAccountErrors = {};

    $scope.emailAddress = '';

    $scope.finished = false;

    $scope.removePageError = function () {
        AdminErrorHandler.removePageError($scope.unlockAccountForm, 'emailAddress', $scope.unlockAccountErrors);
    };
    

    $scope.unlockAccount = function() {
        AdminClient.users.unlock($scope.emailAddress)
            .then(function() { // Success
                    $scope.finished = true;
                },
                function(response) { // Error

                    // user not found coming back on item 'user'
                    // hard code these to always be on emailAddress id
                    response.data.item = 'emailAddress';

                    AdminErrorHandler.handleInputErrors(response.data, response.status, $scope.unlockAccountErrors, $scope.unlockAccountForm);
                }
            );
    };


}]);
app.controller('ResetUserPasswordController', ["$scope", "AdminClient", "$location", "ADMIN_ERRORS", "AdminErrorHandler", "PermissionsService", function($scope, AdminClient, $location, ADMIN_ERRORS, AdminErrorHandler, PermissionsService){
    'use strict';

    if(!PermissionsService.getPermissions().hasProfileEditRole){
        AdminErrorHandler.goToPermissionDeniedPage();
    }

    $scope.resetUserPasswordErrors = {};
    $scope.finished = false;

    $scope.removePageError = function () {
        AdminErrorHandler.removePageError($scope.resetUserPasswordForm, 'idOrEmail', $scope.resetUserPasswordErrors);
    };

    $scope.sendResetPasswordEmail = function() {
        AdminClient.users.sendResetPasswordEmail({'idOrEmail': $scope.idOrEmail})
            .then(function() { // Success
                    $scope.finished = true;
                },
                function(response) { // Error
                    // user not found coming back on item 'user'
                    // hard code these to always be on idOrEmail
                    response.data.item = 'idOrEmail';

                    AdminErrorHandler.handleInputErrors(response.data, response.status, $scope.resetUserPasswordErrors, $scope.resetUserPasswordForm);
                }
            );
    };
}]);
app.controller('UserSearchController', ["$scope", "AdminClient", "ADMIN_ERRORS", "AdminErrorHandler", "$location", "PermissionsService", function($scope, AdminClient, ADMIN_ERRORS, AdminErrorHandler, $location, PermissionsService){
    'use strict';

    if(!PermissionsService.getPermissions().hasProfileViewRole){
        AdminErrorHandler.goToPermissionDeniedPage();
    }

    $scope.searchUserErrors = {};
    $scope.searchUser = {};

    $scope.foundUsersGridOptions = {
        columnDefs: [
            {field: 'id', displayName: 'Profile ID', width:90, resizable:'false', cellTemplate:'<div class="ui-grid-cell-contents"><a href ng-click="grid.appScope.goToUserDetails(grid.getCellValue(row, col))">{{grid.getCellValue(row, col)}}</a></div>'},
            {field: 'emailAddress', displayName: 'Login Email', width:'20%', minWidth:200, resizable:'true'},
            {field: 'mailtoEmailAddress', displayName: 'Mailto Email', width:'20%', minWidth:200, resizable:'true'},
            {field: 'firstName', minWidth:75, resizable:'true'},
            {field: 'lastName', minWidth:75, resizable:'true'},
            {field: 'addresses[0].country', displayName: 'Country', width:80, resizable:'false'},
            {field: 'addresses[0].state', displayName: 'State', width:60, resizable:'false'},
            {field: 'status', displayName: 'Account Status', width:130, resizable:'false'},
            {field: 'emailJob.message', displayName: 'Email Job', minWidth:100, maxWidth: 170, resizable:'true'}
        ],
        enableGridMenu: true
    };

    $scope.findUsers = function() {
        $scope.userNotFound = false;
        $scope.foundUsersGridOptions.data = [];
        if($scope.validateRequest($scope.searchUser)){
            AdminClient.users.getUsers($scope.searchUser)
                .then(function(response){ // Success
                        for(var i=0; i<response.data.length; i++) {
                            if(response.data[i].status && response.data[i].status === 'anonymous') {
                                response.data[i].status = 'lite';
                            }

                            if(response.data[i].emailJob) {
                                response.data[i].emailJob.message = response.data[i].emailJob.jobType + ' ' + response.data[i].emailJob.status;
                            }
                        }
                        $scope.foundUsersGridOptions.data = response.data;
                    },
                    function(response){ // Error
                        if(response.status === 404) {
                            $scope.userNotFound = true;
                            return;
                        }

                        if(response.data.error && response.data.error.length > 0){
                            response.data = response.data.error;
                        }

                        AdminErrorHandler.handleInputErrors(response.data, response.status, $scope.searchUserErrors, $scope.userSearchForm);
                    }
                );
        }
    };

    $scope.goToUserDetails = function(profileId) {
        $location.search('profileId', profileId);
        $location.path('/user-details');
    };

    $scope.removePageError = function(element) {
        AdminErrorHandler.removePageError($scope.userSearchForm, element, $scope.searchUserErrors);
    };

    $scope.validateRequest = function(request) {
        for(var key in request) {
            if(request.hasOwnProperty(key)) {
                if(request[key]) {
                    return true;
                }
            }
        }
        alert("Please enter at least one search term.");
        return false;
    };
}]);
app.controller('SendActivationEmailController', ["$scope", "AdminClient", "$location", "ADMIN_ERRORS", "AdminErrorHandler", "PermissionsService", function($scope, AdminClient, $location, ADMIN_ERRORS, AdminErrorHandler, PermissionsService){
    'use strict';

    if(!PermissionsService.getPermissions().hasProfileEditRole){
        AdminErrorHandler.goToPermissionDeniedPage();
    }

    $scope.sendActivationEmailErrors = {};
    $scope.finished = false;

    $scope.removePageError = function () {
        AdminErrorHandler.removePageError($scope.sendUserActivationEmailForm, 'idOrEmail', $scope.sendActivationEmailErrors);
    };

    $scope.sendUserActivationEmail = function() {
        AdminClient.users.sendActivationEmail({'idOrEmail': $scope.idOrEmail})
            .then(function() { // Success
                    $scope.finished = true;
                },
                function(response) { // Error
                    if(response.data.error && response.data.error.length > 0){
	                    response.data = response.data.error;
                    }
                    if(response.data.item) {
	                    response.data.item = 'idOrEmail';
                    }

                    AdminErrorHandler.handleInputErrors(response.data, response.status, $scope.sendActivationEmailErrors, $scope.sendUserActivationEmailForm);
                }
            );
    };
}]);
app.controller('UserDetailsController', ["$scope", "AdminClient", "$location", "QueryParamParser", "AdminErrorHandler", "ADMIN_CONSTANTS", "$routeParams", "PermissionsService", "ADMIN_ACCESS_GROUPS", "$q", function($scope, AdminClient, $location, QueryParamParser, AdminErrorHandler, ADMIN_CONSTANTS, $routeParams, PermissionsService, ADMIN_ACCESS_GROUPS, $q){
    'use strict';

    if(!PermissionsService.getPermissions().hasProfileViewRole){
        AdminErrorHandler.goToPermissionDeniedPage();
    }

    $scope.profileID = QueryParamParser.getQueryParams().profileId;
    $scope.user = {};
    $scope.mfaRequired = false;
    $scope.userBackup = null;

    $scope.optin = null;
    $scope.optinBackup = null;

    $scope.userLoginHistory = [];

    $scope.selectedTab = null;
    $scope.editingBlock = null;

    $scope.editUserErrors = {};
    $scope.editMarketingErrors = {};
    $scope.editAddressErrors = {};
    $scope.editPhoneErrors = {};

    $scope.busy = false;
    $scope.emailPreferenceServiceError = false;
    $scope.federatedStagedActivationDone = false;

    $scope.permissions=PermissionsService.getPermissions();

    $scope.emailPreferenceServiceError = false;

    $scope.userSubscriptionsGrid = {
        columnDefs: [
            {field: 'subscriptionId', displayName: 'Subscription Code', width:175, resizable:'true', sort:{direction:'asc', priority:0}},
            {field: 'subscriptionTitle', displayName: 'Subscription Topic', minWidth:200, resizable:'true'},
            {field: 'createDate', displayName: 'Date Subscribed', type: 'date', cellFilter: 'date:\''+ADMIN_CONSTANTS.DATE_FORMAT+'\'', minWidth:100, resizable:'true'}
        ],
        enableGridMenu: true
    };

    AdminClient.users.getUsers({profileId: $scope.profileID})
        .then(function(response) {
            $scope.user = response.data[0];
            $scope.processAddressClassifiers();
            $scope.getUserEmailPreferences();
            $scope.getUserOktaDetails();
        },
        function() {
            AdminErrorHandler.goToErrorPage();
        });

    AdminClient.users.getMfaRequired($scope.profileID)
        .then(function(response) {
            $scope.mfaRequired = (response.data === "true");
        },
        function() {
            AdminErrorHandler.goToErrorPage();
        });

    AdminClient.subscriptions.getUserSubscriptions($scope.profileID)
        .then(function(response) {
            $scope.userSubscriptionsGrid.data = response.data;
        },
        function() {
            AdminErrorHandler.goToErrorPage();
        });

    $scope.processAddressClassifiers = function() {
        if($scope.user.addresses){
            $scope.user.addresses.forEach(function(element){
                switch(element.classifier){
                    case "billing":
                        element.classifier = {
                            billing:true,
                            shipping:false
                        };
                        break;
                    case "shipping":
                        element.classifier = {
                            billing:false,
                            shipping:true
                        };
                        break;
                    case "billing_and_shipping":
                        element.classifier = {
                            billing:true,
                            shipping:true
                        };
                        break;
                    default:
                        element.classifier = {
                            billing:false,
                            shipping:false
                        };
                        break;
                }
            });
        }
    };

    $scope.getUserEmailPreferences = function() {
        AdminClient.emailPreferences.checkOptinStatus($scope.user.mailtoEmailAddress)
            .then(function (response) { // Success
                $scope.userEmailPreferences = response.data;

                if(response.data.type === 'OPTIN') {
                    $scope.optin = 'true';
                }
                else if(response.data.type === 'OPTOUT') {
                    $scope.optin = 'false';
                }

                $scope.pageReady = true;
            },
            function (response) { // Error
                if(typeof response.data.item !== undefined){
                    $scope.emailPreferenceServiceError = true;
                    $scope.emailPreferenceServiceErrorMessage = response.data.message + ": " + response.data.detail;
                    $scope.pageReady = true;
                }else{
                    AdminErrorHandler.goToErrorPage();
                }
            });
    };

    $scope.getUserOktaDetails = function() {
        if($scope.user.oktaId){
            AdminClient.users.getUserOktaDetails($scope.user.oktaId)
                .then(function(response) {
                    $scope.oktaDetails = response.data;
                },
                function(response) {
                    console.log('error retrieving Okta details: ' + response);
                });
        }
    };

    AdminClient.constants.marketing.getAffiliations()
        .then(function(response){
            $scope.affiliations = response.data;
        },
        function() {
            AdminErrorHandler.goToErrorPage();
        });

    AdminClient.constants.marketing.getIndustries()
        .then(function(response){
            $scope.industries = response.data;
        },
        function() {
            AdminErrorHandler.goToErrorPage();
        });

    AdminClient.constants.marketing.getJobFunctions()
        .then(function(response){
            $scope.jobFunctions = response.data;
        },
        function() {
            AdminErrorHandler.goToErrorPage();
        });

    AdminClient.constants.marketing.getExpertise()
        .then(function(response){
            $scope.expertiseList = response.data;
        },
        function() {
            AdminErrorHandler.goToErrorPage();
        });

    AdminClient.constants.getLanguages()
        .then(function(response){
            $scope.languages = response.data;
        },
        function() {
            AdminErrorHandler.goToErrorPage();
        });

    AdminClient.users.getLoginHistory($scope.profileID)
        .then(function(response) {
            $scope.userLoginHistory = response.data;
        },
        function() {
            AdminErrorHandler.goToErrorPage();
        });

    AdminClient.users.getUserAcceptTermsAndConditionsDate($scope.profileID)
        .then(function(response) {
            if(response.data) {
                $scope.userAcceptTermsAndConditionsDate = response.data.replaceAll('"', '');
            }
        });

    $scope.edit = function(block) {
        $scope.userBackup=JSON.stringify($scope.user);
        $scope.optinBackup=JSON.stringify($scope.optin);

        switch(block) {
            case "marketing":
                if(!$scope.user.marketing) {
                    $scope.user.marketing = {};
                }
                break;
            case "address":
                if (!$scope.user.addresses || $scope.user.addresses.length === 0) {
                    $scope.user.addresses = [];
                    $scope.user.addresses[0] = {
                        "city": "",
                        "classifier": "unknown",
                        "country": "",
                        "line1": "",
                        "postalCode": "",
                        "type": "business"
                    };
                    $scope.processAddressClassifiers();
                }
                break;
            case "phone":
                if (!$scope.user.phones || $scope.user.phones.length === 0) {
                    $scope.user.phones = [];
                    $scope.user.phones[0] = {
                        "number": "",
                        "type": "unknown"
                    };
                }
                break;
        }

        $scope.editingBlock = block;
    };

    $scope.editCancel = function() {
        $scope.user=JSON.parse($scope.userBackup);
        $scope.optin=JSON.parse($scope.optinBackup);

        $scope.editUserForm.$setPristine();
        $scope.editMarketingForm.$setPristine();
        $scope.editAddressForm.$setPristine();
        $scope.editPhoneForm.$setPristine();

        $scope.editUserErrors = {};
        $scope.editMarketingErrors = {};
        $scope.editAddressErrors = {};
        $scope.editPhoneErrors = {};

        $scope.editingBlock = null;
    };

    $scope.saveStatus = function() {
        $scope.busy = true;
        AdminClient.users.updateUserStatus($scope.profileID, $scope.user.status)
            .then(function(){
                AdminClient.users.getUsers({profileId: $scope.profileID})
                    .then(function(response) {
                        $scope.user = response.data[0];
                        $scope.processAddressClassifiers();
                        $scope.getUserOktaDetails();
                        $scope.editingBlock = null;
                        $scope.busy = false;
                    },
                    function() {
                        AdminErrorHandler.goToErrorPage();
                    });
            },
            function(response){
                AdminErrorHandler.goToErrorPage();
                return $q.reject(response);
            });
    };

    $scope.saveUser = function() {
        $scope.busy = true;
        var updateUser = {};
        updateUser.emailAddress = $scope.user.emailAddress;
        updateUser.mailtoEmailAddress = $scope.user.mailtoEmailAddress;
        updateUser.firstName = $scope.user.firstName;
        updateUser.middleName = $scope.user.middleName;
        updateUser.lastName = $scope.user.lastName;
        updateUser.sasContact = $scope.user.sasContact;
        updateUser.language = $scope.user.language;

        AdminClient.users.updateUser($scope.profileID, updateUser)
            .then(function() {
                AdminClient.users.getUsers({profileId: $scope.profileID})
                    .then(function(response) {
                        $scope.user = response.data[0];
                        $scope.processAddressClassifiers();
                        $scope.editingBlock = null;
                        $scope.busy = false;
                    },
                    function() {
                        AdminErrorHandler.goToErrorPage();
                    });
            },
            function(response) {
                $scope.busy = false;
                AdminErrorHandler.handleInputErrors(response.data, response.status, $scope.editUserErrors, $scope.editUserForm);
                return $q.reject(response);
            });
    };

    $scope.removeUserError = function(field) {
        AdminErrorHandler.removePageError($scope.editUserForm, field, $scope.editUserErrors);
    };

    $scope.saveMarketing = function() {
        $scope.busy = true;

        var updateUserMarketing = {};
        updateUserMarketing.affiliation = $scope.user.marketing.affiliation;
        updateUserMarketing.company = $scope.user.marketing.company;
        updateUserMarketing.industry = $scope.user.marketing.industry;
        updateUserMarketing.jobFunction = $scope.user.marketing.jobFunction;
        updateUserMarketing.jobTitle = $scope.user.marketing.jobTitle;
        updateUserMarketing.expertise = $scope.user.marketing.expertise;

        AdminClient.users.updateUserMarketing($scope.profileID, updateUserMarketing)
            .then(function() {
                AdminClient.users.getUsers({profileId: $scope.profileID})
                    .then(function(response) {
                        $scope.user = response.data[0];
                        $scope.processAddressClassifiers();
                        $scope.editingBlock = null;
                        $scope.busy = false;
                    },
                    function() {
                        AdminErrorHandler.goToErrorPage();
                    });
            },
            function(response) {
                AdminErrorHandler.handleInputErrors(response.data, response.status, $scope.editMarketingErrors, $scope.editMarketingForm);
                $scope.busy = false;
                return $q.reject(response);
            });
    };

    $scope.removeMarketingError = function(field) {
        AdminErrorHandler.removePageError($scope.editMarketingForm, field, $scope.editMarketingErrors);
    };

    $scope.saveAddress = function() {
        $scope.busy = true;

        var updateUserAddress = {};
        updateUserAddress.city = $scope.user.addresses[0].city;
        updateUserAddress.country = $scope.user.addresses[0].country;
        updateUserAddress.addressLine1 = $scope.user.addresses[0].line1;
        updateUserAddress.addressLine2 = $scope.user.addresses[0].line2;
        updateUserAddress.addressLine3 = $scope.user.addresses[0].line3;
        updateUserAddress.postalCode = $scope.user.addresses[0].postalCode;
        updateUserAddress.state = $scope.user.addresses[0].state;
        updateUserAddress.addressType = $scope.user.addresses[0].type;
        if($scope.user.addresses[0].classifier.billing && $scope.user.addresses[0].classifier.shipping) {
            updateUserAddress.classifier = 'billing_and_shipping';
        } else if($scope.user.addresses[0].classifier.billing) {
            updateUserAddress.classifier = 'billing';
        } else if($scope.user.addresses[0].classifier.shipping) {
            updateUserAddress.classifier = 'shipping';
        }

        AdminClient.users.updateUserAddress($scope.profileID, updateUserAddress)
            .then(function() {
                AdminClient.users.getUsers({profileId: $scope.profileID})
                    .then(function(response) {
                        $scope.user = response.data[0];
                        $scope.processAddressClassifiers();
                        $scope.editingBlock = null;
                        $scope.busy = false;
                    },
                    function() {
                        AdminErrorHandler.goToErrorPage();
                    });
            },
            function(response) {
                $scope.processErrorResponseForArray(response, '0');
                AdminErrorHandler.handleInputErrors(response.data, response.status, $scope.editAddressErrors, $scope.editAddressForm);
                $scope.busy = false;
                return $q.reject(response);
            });
    };

    $scope.removeAddressError = function(field) {
        AdminErrorHandler.removePageError($scope.editAddressForm, field, $scope.editAddressErrors);
    };

    $scope.savePhone = function() {
        $scope.busy = true;

        var updateUserPhone = {};
        updateUserPhone.phoneType = $scope.user.phones[0].type;
        updateUserPhone.phone = $scope.user.phones[0].number;

        AdminClient.users.updateUserPhone($scope.profileID, updateUserPhone)
            .then(function(){
                AdminClient.users.getUsers({profileId: $scope.profileID})
                    .then(function(response) {
                        $scope.user = response.data[0];
                        $scope.processAddressClassifiers();
                        $scope.editingBlock = null;
                        $scope.busy = false;
                    },
                    function() {
                        AdminErrorHandler.goToErrorPage();
                    });
            },
            function(response) {
                $scope.processErrorResponseForArray(response, '0');
                AdminErrorHandler.handleInputErrors(response.data, response.status, $scope.editPhoneErrors, $scope.editPhoneForm);
                $scope.busy = false;
                return $q.reject(response);
            });
    };

    $scope.removePhoneError = function(field) {
        AdminErrorHandler.removePageError($scope.editPhoneForm, field, $scope.editPhoneErrors);
    };

    $scope.saveOptin = function() {
        $scope.busy = true;

        if(angular.isDefined($scope.optin)){
            if($scope.optin === "true"){
                AdminClient.emailPreferences.optin($scope.user.mailtoEmailAddress,false)
                    .then(function(){
                        $scope.editingBlock = null;
                        $scope.busy = false;
                    },function(response){
                        $scope.editingBlock = null;
                        $scope.busy = false;
                        if(typeof response.data.item !== undefined){
                            $scope.emailPreferenceServiceError = true;
                            $scope.emailPreferenceServiceErrorMessage = response.data.message;
                            return $q.reject(response);
                        }else{
                            AdminErrorHandler.goToErrorPage();
                        }
                    });
            }else{
                AdminClient.emailPreferences.optout($scope.user.mailtoEmailAddress)
                    .then(function(){
                        $scope.editingBlock = null;
                        $scope.busy = false;
                    },function(response){
                        $scope.editingBlock = null;
                        $scope.busy = false;
                        if(typeof response.data.item !== undefined){
                            $scope.emailPreferenceServiceError = true;
                            $scope.emailPreferenceServiceErrorMessage = response.data.message;
                            return $q.reject(response);
                        }else{
                            AdminErrorHandler.goToErrorPage();
                        }
                    });
            }
        }
    };

    $scope.saveMfaRequired = function() {
        $scope.busy = true;

        AdminClient.users.setMfaRequired($scope.profileID, $scope.mfaRequired)
            .then(function(){
                $scope.editingBlock = null;
                $scope.busy = false;
            },
            function(response) {
                AdminErrorHandler.goToErrorPage();
                return $q.reject(response);
            });
    }

    $scope.processErrorResponseForArray = function(response, index) {
        if(angular.isArray(response.data)){
            response.data.forEach(function(element){
                element.item = element.item + index;
            });
        }else{
            response.data.item = response.data.item + index;
        }
    };

    $scope.editUserSubscriptions = function() {
        $location.path('/edit-user-subscriptions');
    };

    $scope.deleteUser = function() {
        $scope.busy = true;
        delete $scope.bottomButtonsErrorText;
        var shouldDelete = confirm("Are you sure you want to delete this user? This action cannot be undone.");

        if(shouldDelete) {
            AdminClient.users.deleteUser($scope.user.id)
                .then(function(){
                    $scope.finishedDelete = true;
                },
                function(response) { // Failure
                    $scope.busy = false;
                    if(AdminErrorHandler.isClientError(response.status) && typeof response.data.message !== undefined){
                        $scope.bottomButtonsErrorText = response.data.message;
                    }else{
                        AdminErrorHandler.goToErrorPage();
                    }
                });
        } else {
            $scope.busy = false;
        }
    };

    $scope.sendResetPasswordEmail = function() {
        $scope.busy=true;
        delete $scope.bottomButtonsErrorText;
        AdminClient.users.sendResetPasswordEmail({'idOrEmail': $scope.user.emailAddress})
            .then(function() { // Success
                AdminClient.users.getUsers({profileId: $scope.profileID})
                    .then(function(response) {
                        $scope.user = response.data[0];
                        $scope.processAddressClassifiers();
                        $scope.busy = false;
                    },
                    function() {
                        AdminErrorHandler.goToErrorPage();
                    });
            },
            function(response) { // Failure
                $scope.busy = false;
                if(AdminErrorHandler.isClientError(response.status) && typeof response.data.message !== undefined){
                    $scope.bottomButtonsErrorText = response.data.message;
                }else{
                    AdminErrorHandler.goToErrorPage();
                }
            });
    };

    $scope.forceOktaAdPush = function() {
        $scope.busy=true;
        delete $scope.bottomButtonsErrorText;
        AdminClient.users.forceOktaAdPush({'idOrEmail': $scope.user.emailAddress})
            .then(function() { // Success
                $scope.busy=false;
            },
            function(response) { // Failure
                $scope.busy = false;
                if(AdminErrorHandler.isClientError(response.status) && typeof response.data.message !== undefined){
                    $scope.bottomButtonsErrorText = response.data.message;
                }else{
                    AdminErrorHandler.goToErrorPage();
                }
            });
    };

    $scope.activateOktaUser = function() {
        $scope.busy=true;
        delete $scope.activateOktaUserErrorText;
        AdminClient.users.activateOktaUser({'idOrEmail': $scope.user.emailAddress})
            .then(function() { // Success
                AdminClient.users.getUsers({profileId: $scope.profileID})
                    .then(function(response) {
                        $scope.user = response.data[0];
                        $scope.processAddressClassifiers();
                        $scope.getUserOktaDetails();
                        $scope.federatedStagedActivationDone = true;
                        $scope.busy = false;
                    },
                    function() {
                        AdminErrorHandler.goToErrorPage();
                    });
            },
            function(response) { // Failure
                $scope.busy = false;
                if(AdminErrorHandler.isClientError(response.status) && typeof response.data.message !== undefined){
                    $scope.activateOktaUserErrorText = response.data.message;
                }else{
                    AdminErrorHandler.goToErrorPage();
                }
            });
    };

    $scope.resendEmail = function() {
        $scope.busy=true;
        delete $scope.resendEmailErrorText;
        AdminClient.users.resendEmail({'emailJobID': $scope.user.emailJob.emailJobID})
            .then(function(){ // Success
                AdminClient.users.getUsers({profileId: $scope.profileID})
                    .then(function(response) {
                        $scope.user = response.data[0];
                        $scope.processAddressClassifiers();
                        $scope.busy = false;
                    },
                    function() {
                        AdminErrorHandler.goToErrorPage();
                    });
            },
            function(response) { // Failure
                $scope.busy = false;
                if(AdminErrorHandler.isClientError(response.status) && typeof response.data.message !== undefined){
                    $scope.resendEmailErrorText = response.data.message;
                }else{
                    AdminErrorHandler.goToErrorPage();
                }
            });
    };

    $scope.selectTab = function(tab) {
        if(!$scope.busy && $scope.editingBlock === null){
            $scope.selectedTab = tab;

            if(tab === 'subscriptions') {
                // This hack fakes a window resize after selecting the Subscriptions tab to work around https://github.com/angular-ui/ui-grid/issues/855
                window.setTimeout(function() {
                    $(window).resize();
                    $(window).resize();
                }, 1);
            }
        }
    };

    if($routeParams.selectedTab === 'subscriptions') {
        $scope.selectTab('subscriptions');
    } else {
        $scope.selectTab('userInfo');
    }
}]);

app.controller('EditUserSubscriptionsController', ["$scope", "AdminClient", "$location", "QueryParamParser", "AdminErrorHandler", "$q", "PermissionsService", function($scope, AdminClient, $location, QueryParamParser, AdminErrorHandler, $q, PermissionsService){
    'use strict';

    if(!PermissionsService.getPermissions().hasProfileEditRole){
        AdminErrorHandler.goToPermissionDeniedPage();
    }

    var sasProfileID = QueryParamParser.getQueryParams().profileId;
    $scope.userSubscriptions = {};

    $scope.userSubscriptionsGridOptions = {
        columnDefs: [
            {field: 'subscriptionId', displayName: 'Subscription Code', width:175, resizable:'true', sort:{direction:'asc', priority:0}},
            {field: 'title', displayName: 'Subscription Topic', minWidth:200, resizable:'true'},
            {field: 'subscriptionId', displayName: 'Action', minWidth:100, resizable:'true',
                cellTemplate:
                '<div class="ui-grid-cell-contents" ng-if="!grid.appScope.subscriptionsSelected[grid.getCellValue(row, col)]">' +
                    '<a href ng-click="grid.appScope.addSubscription(grid.getCellValue(row, col))">Add</a>' +
                '</div>' +
                '<div class="ui-grid-cell-contents" ng-if="grid.appScope.subscriptionsSelected[grid.getCellValue(row, col)]">' +
                    '<a href ng-click="grid.appScope.removeSubscription(grid.getCellValue(row, col))">Remove</a>' +
                '</div>'}
        ],
        enableGridMenu: true
    };

    $scope.addSubscription = function(subscriptionId) {
        AdminClient.subscriptions.addUserSubscription(subscriptionId, sasProfileID)
            .then(
                function(){
                    $scope.subscriptionsSelected[subscriptionId] = true;
                },
                function() {
                    AdminErrorHandler.goToErrorPage();
                });
    };

    $scope.removeSubscription = function(subscriptionId) {
        AdminClient.subscriptions.removeUserSubscription(subscriptionId, sasProfileID)
            .then(
                function(){
                    $scope.subscriptionsSelected[subscriptionId] = false;
                },
                function(){
                    AdminErrorHandler.goToErrorPage();
                });
    };

    $scope.goBack = function() {
        $location.path('/user-details/subscriptions');
    };

    var requests = [];
    requests.push(AdminClient.subscriptions.getAllSubscriptions()
        .then(function(response){
                $scope.userSubscriptionsGridOptions.data = response.data;
            },
            function(){
                AdminErrorHandler.goToErrorPage();
            }));

    requests.push(AdminClient.subscriptions.getUserSubscriptions(sasProfileID)
        .then(function(response) {
                $scope.userSubscriptions = response.data;
            },
            function(){
                AdminErrorHandler.goToErrorPage();
            }));

    $q.all(requests).then(function() {
        $scope.subscriptionsSelected = {};
        for(var i=0; i<$scope.userSubscriptions.length; i++){
            $scope.subscriptionsSelected[$scope.userSubscriptions[i].subscriptionId] = true;
            console.log($scope.userSubscriptions[i].subscriptionId);
        }
        $scope.pageReady = true;
    });
}]);
app.controller('UploadDormantUsersController', ["$scope", "AdminClient", "PermissionsService", "AdminErrorHandler", function($scope, AdminClient, PermissionsService, AdminErrorHandler){
    'use strict';

    if(!PermissionsService.getPermissions().hasSuperUserRole){
        AdminErrorHandler.goToPermissionDeniedPage();
    }

    $scope.info = {finished:false, csvError:false};
    $scope.response = {};
    $scope.duration = 0;

    $scope.expectedFields = [
        "email"
    ];

    $scope.expectedFieldsString = $scope.expectedFields.join();

    $scope.importErrorsGridOptions = {
        columnDefs: [
            {field: 'entry', displayName: 'Line #', width:75, resizable:'true'},
            {field: 'errorMessage', displayName: 'Error', resizable:'true'}
        ],
        enableGridMenu: true
    };

    $scope.aggregateFunction = function(aggregateResponse, currentResponse) {
        aggregateResponse.totalLinesProcessed += currentResponse.totalLinesProcessed;
        aggregateResponse.numberOfFailures += currentResponse.numberOfFailures;
        aggregateResponse.errors = aggregateResponse.errors.concat(currentResponse.errors);
    };

    $scope.clientFunction = AdminClient.users.setDormantUsers;
}]);
app.controller('BulkDeleteUsersController', ["$scope", "AdminClient", "PermissionsService", "AdminErrorHandler", function($scope, AdminClient, PermissionsService, AdminErrorHandler){
    'use strict';

    if(!PermissionsService.getPermissions().hasSuperUserRole){
        AdminErrorHandler.goToPermissionDeniedPage();
    }

    $scope.info = {finished:false, csvError:false};
    $scope.response = {};
    $scope.duration = 0;

    $scope.expectedFields = [
        "email"
    ];

    $scope.expectedFieldsString = $scope.expectedFields.join();

    $scope.importErrorsGridOptions = {
        columnDefs: [
            {field: 'entry', displayName: 'Line #', width:75, resizable:'true'},
            {field: 'errorMessage', displayName: 'Error', resizable:'true'}
        ],
        enableGridMenu: true
    };

    $scope.aggregateFunction = function(aggregateResponse, currentResponse) {
        aggregateResponse.totalLinesProcessed += currentResponse.totalLinesProcessed;
        aggregateResponse.numberOfFailures += currentResponse.numberOfFailures;
        aggregateResponse.numberOfRecordsVerified += currentResponse.numberOfRecordsVerified;
        aggregateResponse.errors = aggregateResponse.errors.concat(currentResponse.errors);
    };

    $scope.clientFunction = AdminClient.users.bulkDeleteUsers;
}]);
app.controller('BulkDeleteUsersByDomainController', ["$scope", "AdminClient", "PermissionsService", "AdminErrorHandler", function($scope, AdminClient, PermissionsService, AdminErrorHandler){
    'use strict';

    if(!PermissionsService.getPermissions().hasSuperUserRole){
        AdminErrorHandler.goToPermissionDeniedPage();
    }

    $scope.domainEntered = false;
    $scope.domainDeleteErrors = false;

    $scope.bulkDeleteUsersByDomain = function() {
        $scope.processing = true;
        var convert = $scope.domain;
        var test = convert.match(/[()!~`|[\]{}<>,;*:+#$^&'"=?]/);
        if (convert.contains("%")) {
            convert = convert.replace(/%/g,"*");
        }
        if (test != null) {
            $scope.domainDeleteErrors = true;

        } else {
            AdminClient.users.bulkDeleteUsersByDomain(convert)
                .then(function(response){ // Success
                        $scope.deleteResponse = response.data;
                        $scope.domainEntered = true;
                        $scope.processing = false;
                    },
                    function(){ // Error
                        $scope.domainDeleteErrors = true;
                        $scope.processing = false;
                    }
                );
        }
    };
}]);

app.controller('ViewFederatedDomainsController', ["$scope", "AdminClient", "$location", "ADMIN_ERRORS", "AdminErrorHandler", "PermissionsService", function($scope, AdminClient, $location, ADMIN_ERRORS, AdminErrorHandler, PermissionsService){
    'use strict';

    if(!PermissionsService.getPermissions().hasProfileViewRole){
        AdminErrorHandler.goToPermissionDeniedPage();
    }

    $scope.ready = false;
    $scope.federatedDomains = {};

    AdminClient.constants.getFederatedDomains().then(function (response){ // Success
        $scope.federatedDomains = response.data;
        $scope.ready=true;
    },function(){ // Error
        AdminErrorHandler.goToErrorPage();
    });

}]);
app.controller('OptinUserController', ["$scope", "AdminClient", "$location", "ADMIN_ERRORS", "AdminErrorHandler", "PermissionsService", function($scope, AdminClient, $location, ADMIN_ERRORS, AdminErrorHandler, PermissionsService){
    'use strict';

    if(!PermissionsService.getPermissions().hasNewsletterRole){
        AdminErrorHandler.goToPermissionDeniedPage();
    }

    $scope.optinUserErrors = {};
    $scope.finished = false;

    $scope.removePageError = function () {
        AdminErrorHandler.removePageError($scope.optinUserForm, 'emailAddress', $scope.optinUserErrors);
    };

    $scope.optin = function() {
        AdminClient.emailPreferences.optin($scope.emailAddress, $scope.sendEmailFlag)
            .then(function() { // Success
                    $scope.finished = true;
                },
                function(response) {
                    $scope.finished = false;
                    AdminErrorHandler.handleOptinServiceUnavailableError(response.data, response.status, $scope.optinUserErrors, $scope.optinUserForm);
                    AdminErrorHandler.handleInputErrors(response.data, response.status, $scope.optinUserErrors, $scope.optinUserForm);
                }
            );
    };
}]);
app.controller('OptoutUserController', ["$scope", "AdminClient", "$location", "ADMIN_ERRORS", "AdminErrorHandler", "PermissionsService", function($scope, AdminClient, $location, ADMIN_ERRORS, AdminErrorHandler, PermissionsService){
    'use strict';

    if(!PermissionsService.getPermissions().hasNewsletterRole){
        AdminErrorHandler.goToPermissionDeniedPage();
    }

    $scope.optoutUserErrors = {};
    $scope.finished = false;

    $scope.removePageError = function () {
        AdminErrorHandler.removePageError($scope.optoutUserForm, 'emailAddress', $scope.optoutUserErrors);
    };

    $scope.optout = function() {
        AdminClient.emailPreferences.optout($scope.emailAddress)
            .then(function() { // Success
                    $scope.finished = true;
                },
                function(response) { // Error
                    $scope.finished = false;
                    AdminErrorHandler.handleOptinServiceUnavailableError(response.data, response.status, $scope.optoutUserErrors, $scope.optoutUserForm);
                    AdminErrorHandler.handleInputErrors(response.data, response.status, $scope.optoutUserErrors, $scope.optoutUserForm);
                }
            );
    };
}]);
app.controller('CheckOptinStatusController', ["$scope", "AdminClient", "$location", "ADMIN_ERRORS", "AdminErrorHandler", "$filter", "PermissionsService", function($scope, AdminClient, $location, ADMIN_ERRORS, AdminErrorHandler, $filter, PermissionsService){
    'use strict';

    if(!PermissionsService.getPermissions().hasProfileViewRole){
        AdminErrorHandler.goToPermissionDeniedPage();
    }

    $scope.checkOptinStatusErrors = {};
    $scope.finished = false;

    $scope.removePageError = function () {
        AdminErrorHandler.removePageError($scope.checkOptinStatusForm, 'emailAddress', $scope.checkOptinStatusErrors);
    };

    $scope.checkOptinStatus = function() {
        AdminClient.emailPreferences.checkOptinStatus($scope.emailAddress)
            .then(function(response) { // Success
                    if(response.data.activationDate) {
                        response.data.activationDate = $filter('date')(new Date(response.data.activationDate), 'medium', 'en_US');
                    }

                    if(response.data.createDate) {
                        response.data.createDate = $filter('date')(new Date(response.data.createDate), 'medium', 'en_US');
                    }

                    if (response.data.etsId !== undefined && response.data.etsId === 0) {
                        delete response.data['etsId'];
                    }

                    if(response.data.verifyDate) {
                        response.data.verifyDate = $filter('date')(new Date(response.data.verifyDate), 'medium', 'en_US');
                    }

                    $scope.emailPreferences = response.data;
                    $scope.finished = true;
                },
                function(response) { // Error
                    $scope.finished = false;
                    AdminErrorHandler.handleOptinServiceUnavailableError(response.data, response.status, $scope.checkOptinStatusErrors, $scope.checkOptinStatusForm);
                    AdminErrorHandler.handleInputErrors(response.data, response.status, $scope.checkOptinStatusErrors, $scope.checkOptinStatusForm);
                }
            );
    };
}]);
app.controller('ImportEmailPreferencesController', ["$scope", "AdminClient", "PermissionsService", "AdminErrorHandler", function($scope, AdminClient, PermissionsService, AdminErrorHandler){
    'use strict';

    if(!PermissionsService.getPermissions().hasNewsletterRole){
        AdminErrorHandler.goToPermissionDeniedPage();
    }

    $scope.info = {finished:false, csvError:false};
    $scope.response = {};
    $scope.duration = 0;

    $scope.expectedFields = [
        "action","email","date","source","countrycode","languagecode"
    ];

    $scope.expectedFieldsString = $scope.expectedFields.join();

    $scope.importErrorsGridOptions = {
        columnDefs: [
            {field: 'entry', displayName: 'Line #', width:75, resizable:'true'},
            {field: 'errorMessage', displayName: 'Error', resizable:'true'}
        ],
        enableGridMenu: true
    };

    $scope.aggregateFunction = function(aggregateResponse, currentResponse) {
        aggregateResponse.totalLinesProcessed += currentResponse.totalLinesProcessed;
        aggregateResponse.numberOfFailures += currentResponse.numberOfFailures;
        aggregateResponse.numberOfSuccessfulOptins += currentResponse.numberOfSuccessfulOptins;
        aggregateResponse.numberOfSuccessfulOptouts += currentResponse.numberOfSuccessfulOptouts;
        aggregateResponse.numberOfLocaleUpdates += currentResponse.numberOfLocaleUpdates;
        aggregateResponse.numberOfExistingRecordsVerified += currentResponse.numberOfExistingRecordsVerified;
        aggregateResponse.errors = aggregateResponse.errors.concat(currentResponse.errors);
    };

    $scope.clientFunction = AdminClient.emailPreferences.setEmailPreferences;
}]);
app.controller('DeleteEmailPreferencesController', ["$scope", "AdminClient", "$location", "ADMIN_ERRORS", "AdminErrorHandler", "PermissionsService", function($scope, AdminClient, $location, ADMIN_ERRORS, AdminErrorHandler, PermissionsService){
    'use strict';

    if(!PermissionsService.getPermissions().hasNewsletterRole){
        AdminErrorHandler.goToPermissionDeniedPage();
    }

    $scope.deleteEmailPreferencesErrors = {};
    $scope.finished = false;

    $scope.removePageError = function () {
        AdminErrorHandler.removePageError($scope.deleteEmailPreferencesForm, 'emailAddress', $scope.deleteEmailPreferencesErrors);
    };

    $scope.deleteEmailPreferences = function() {
        AdminClient.emailPreferences.deleteEmailPreferences($scope.emailAddress)
            .then(function() { // Success
                    $scope.finished = true;
                },
                function(response) { // Error
                    $scope.finished = false;
                    AdminErrorHandler.handleOptinServiceUnavailableError(response.data, response.status, $scope.deleteEmailPreferencesErrors, $scope.deleteEmailPreferencesForm);
                    AdminErrorHandler.handleInputErrors(response.data, response.status, $scope.deleteEmailPreferencesErrors, $scope.deleteEmailPreferencesForm);
                }
            );
    };
}]);
app.controller('ManageLocalesController', ["$scope", "AdminClient", "$location", "ADMIN_ERRORS", "$filter", "AdminErrorHandler", "PermissionsService", function($scope, AdminClient, $location, ADMIN_ERRORS, $filter, AdminErrorHandler, PermissionsService){
    'use strict';

    if(!PermissionsService.getPermissions().hasTranslationEditRole){
        AdminErrorHandler.goToPermissionDeniedPage();
    }

    $scope.newLocale = {};
    $scope.createLocaleErrors = {};

    $scope.foundLocalesGridOptions = {
        columnDefs: [
            {field: 'localeDefinitionId', cellTemplate:'<div class="ui-grid-cell-contents"><a ng-href="#edit-locale?localeDefinitionId={{grid.getCellValue(row, col)}}">{{grid.getCellValue(row, col)}}</a></div>'},
            {field: 'code'},
            {field: 'name'},
            {field: 'display'}],
        enableGridMenu: true
    };


    var localLocales = function() {
        AdminClient.locales.getLocales().then(function (response){ // Success
                $scope.foundLocalesGridOptions.data = response.data;
            },
            function(){ // Error
                AdminErrorHandler.goToErrorPage();
            }
        );

    };

    localLocales();

    $scope.createLocale = function() {
        AdminClient.locales.create($scope.newLocale.code, $scope.newLocale.name, $scope.newLocale.display)
            .then(function(){ // Success
                    $scope.newLocale = {};
                    $scope.createLocaleForm.$setPristine();
                    localLocales();
                },
                function(response){ // Error
                    AdminErrorHandler.handleInputErrors(response.data, response.status, $scope.createLocaleErrors, $scope.createLocaleForm);
                }
            );
    };


    $scope.changeCreateLocale = function(element) {
        AdminErrorHandler.removePageErrors($scope.createLocaleForm, element, $scope.createLocaleErrors);
    };


}]);
app.controller('EditLocaleController', ["$scope", "AdminClient", "$location", "QueryParamParser", "AdminErrorHandler", "PermissionsService", function($scope, AdminClient, $location, QueryParamParser, AdminErrorHandler, PermissionsService){
    'use strict';

    if(!PermissionsService.getPermissions().hasTranslationEditRole){
        AdminErrorHandler.goToPermissionDeniedPage();
    }

    var localeID = QueryParamParser.getQueryParams().localeDefinitionId;
    
    $scope.localeErrors = {};
    $scope.localeID = {}
    $scope.locale = {}


    if(localeID) {
        AdminClient.locales.getLocale(localeID).then(function (response){ // Success
                $scope.locale = response.data;
            },
            function(){
                AdminErrorHandler.goToErrorPage();
            }
        );
    }
    else {
        AdminErrorHandler.goToErrorPage();
    }

    $scope.saveLocale = function() {
        AdminClient.locales.update($scope.locale.localeDefinitionId, $scope.locale.code, $scope.locale.name, $scope.locale.display)
            .then(function() { // Success
                    $location.path('manage-locales');
                },
                function(response) {
                    AdminErrorHandler.handleInputErrors(response.data, response.status, $scope.localeErrors, $scope.localeForm);
                }
            );
    };
    

    $scope.cancel = function() {
        $location.path('manage-locales');
    };
}]);
app.controller('ImportSubscribersController', ["$scope", "AdminClient", "AdminErrorHandler", "PermissionsService", function($scope, AdminClient, AdminErrorHandler, PermissionsService){
    'use strict';

    if(!PermissionsService.getPermissions().hasNewsletterRole){
        AdminErrorHandler.goToPermissionDeniedPage();
    }

    $scope.info = {finished:false, csvError:false};
    $scope.response = {};
    $scope.duration = 0;

    $scope.importErrorsGridOptions = {
        columnDefs: [
            {field: 'entry', displayName: 'Line #', width:75, resizable:'true'},
            {field: 'errorMessage', displayName: 'Error', resizable:'true'}
        ],
        enableGridMenu: true
    };

    $scope.expectedFields = [
        "subcodenum","email","salutation","firstname","lastname","company","title","address1","address2","address3","city","state","zip","country","phone","datesubscribed","affiliation","industry","sourcesystem"
    ];

    $scope.keySpellingCorrections = {
        affilation:"affiliation"
    };

    $scope.expectedFieldsString = $scope.expectedFields.join();

    $scope.aggregateFunction = function(aggregateResponse, currentResponse) {
        aggregateResponse.totalLinesProcessed += currentResponse.totalLinesProcessed;
        aggregateResponse.numberOfFailures += currentResponse.numberOfFailures;
        aggregateResponse.errors.concat(currentResponse.errors);
    };

    $scope.clientFunction = AdminClient.subscriptions.uploadBulkSubscribe;
}]);
app.controller('ImportUnSubscribersController', ["$scope", "AdminClient", "PermissionsService", "AdminErrorHandler", function($scope, AdminClient, PermissionsService, AdminErrorHandler){
    'use strict';

    if(!PermissionsService.getPermissions().hasNewsletterRole){
        AdminErrorHandler.goToPermissionDeniedPage();
    }

    $scope.info = {finished:false, csvError:false};
    $scope.response = {};
    $scope.duration = 0;

    $scope.importErrorsGridOptions = {
        columnDefs: [
            {field: 'entry', displayName: 'Line #', width:75, resizable:'true'},
            {field: 'errorMessage', displayName: 'Error', resizable:'true'}
        ],
        enableGridMenu: true
    };

    $scope.expectedFields = [
        "subcodenum","email"
    ];
    $scope.expectedFieldsString = $scope.expectedFields.join();

    $scope.aggregateFunction = function(aggregateResponse, currentResponse) {
        aggregateResponse.totalLinesProcessed += currentResponse.totalLinesProcessed;
        aggregateResponse.numberOfFailures += currentResponse.numberOfFailures;
        aggregateResponse.errors.concat(currentResponse.errors);
    };

    $scope.clientFunction = AdminClient.subscriptions.uploadBulkUnsubscribe;
}]);
app.controller('ManageSubscriptionsController', ["$scope", "AdminClient", "AdminErrorHandler", "$filter", "$location", "PermissionsService", function($scope, AdminClient, AdminErrorHandler, $filter, $location, PermissionsService){
    'use strict';

    if(!PermissionsService.getPermissions().hasNewsletterRole){
        AdminErrorHandler.goToPermissionDeniedPage();
    }

    $scope.goToViewSubscription = function(subscriptionId) {
        $location.search('subscriptionId', subscriptionId);
        $location.path('/view-subscription');
    };

    $scope.goToCreateSubscription = function() {
        $location.path('/create-subscription');
    };

    $scope.subscriptionsGridOptions = {
        columnDefs: [
            {field: 'subscriptionId', displayName:'Id', width:100, resizable:'true', cellTemplate:'<div class="ui-grid-cell-contents"><a href ng-click="grid.appScope.goToViewSubscription(grid.getCellValue(row, col))">{{grid.getCellValue(row, col)}}</a></div>'},
            {field: 'title', minWidth:300, resizable:'true'},
            {field: 'subscriptionTypeName', displayName: 'Type', minWidth:200, resizable:'true'},
            {field: 'countryOfOrigin', displayName: 'Country', minWidth:50, resizable:'true'},
            {field: 'createDate', minWidth:200, resizable:'true'},
            {field: 'lastModified', minWidth:200, resizable:'true'},
            {field: 'sortOrder', minWidth:100, resizable:'true'}
        ],
        enableGridMenu: true
    };

    AdminClient.subscriptions.getAllSubscriptions().then(
        function(response){
            $scope.subscriptionsGridOptions.data = response.data;
            $scope.subscriptionsGridOptions.data.forEach(function updateRows(row) {
                row.createDate = $filter('date')(new Date(row.createDate), 'medium', 'en_US');
                row.lastModified = $filter('date')(new Date(row.lastModified), 'medium', 'en_US');
            });
            $scope.pageReady = true;
        },
        function(){
            AdminErrorHandler.goToErrorPage();
        }
    )
}]);
app.controller('ViewSubscriptionController', ["$scope", "AdminClient", "AdminErrorHandler", "$filter", "$location", "QueryParamParser", "PermissionsService", function($scope, AdminClient, AdminErrorHandler, $filter, $location, QueryParamParser, PermissionsService){
    'use strict';

    if(!PermissionsService.getPermissions().hasNewsletterRole){
        AdminErrorHandler.goToPermissionDeniedPage();
    }

    $scope.cancel = function(){
        $location.search('subscriptionId', null);
        $location.path('manage-subscriptions');
    };

    $scope.edit = function(){
        $location.path('edit-subscription');
    };

    $scope.clearTabs = function() {
        $scope.infoSelected = false;
        $scope.filtersSelected = false;
    };

    $scope.createFilter = function() {
        $location.path('create-subscription-filter');
    };

    $scope.deleteFilter = function(id) {
        AdminClient.subscriptions.deleteSubscriptionInstance(id).then(
            function(){
                $scope.getSubscriptionInstances();
            },
            function(){
                AdminErrorHandler.goToErrorPage();
            })
    };

    $scope.editFilter = function(id) {
        $location.search('subscriptionInstanceId', id);
        $location.path('edit-subscription-filter');
    };

    $scope.transformAffiliations = function(subscriptionInstances) {
        for (var i = 0; i < subscriptionInstances.length; i++) {
            if (subscriptionInstances[i].affiliation) {
                for (var j = 0; j < $scope.affiliations.length; j++) {
                    if ($scope.affiliations[j].code === subscriptionInstances[i].affiliation) {
                        subscriptionInstances[i].affiliation = $scope.affiliations[j].name;
                        break;
                    }
                }
            }
        }
    };

    $scope.getSubscriptionInstances = function(){
        AdminClient.subscriptions.getSubscriptionInstances($scope.subscriptionId).then(
            function(response){
                if(response.data) {
                    $scope.transformAffiliations(response.data);
                }
                $scope.subscriptionInstances = response.data;
            },
            function(){
                AdminErrorHandler.goToErrorPage();
            }
        );
    };

    $scope.getSubscription = function() {
        AdminClient.subscriptions.getSubscription($scope.subscriptionId).then(
            function(response){
                $scope.subscription = response.data;
                $scope.subscription.createDate = $filter('date')(new Date($scope.subscription.createDate), 'medium', 'en_US');
                $scope.subscription.lastModified = $filter('date')(new Date($scope.subscription.lastModified), 'medium', 'en_US');
            },
            function(){
                AdminErrorHandler.goToErrorPage();
            }
        );
    };

    $scope.getSubscriptionInfo = function() {
        return AdminClient.constants.marketing.getAffiliations().then(
                function(response){
                    $scope.affiliations = response.data;
                    $scope.getSubscriptionInstances();
                    $scope.getSubscription();
                },
                function(){
                    AdminErrorHandler.goToErrorPage();
                });
    };

    $scope.subscriptionId = QueryParamParser.getQueryParams().subscriptionId;
    if($scope.subscriptionId) {
        $scope.infoSelected = true;
        $scope.getSubscriptionInfo().then(function(){
            $scope.pageReady = true;
        });
    }
    else {
        $scope.cancel();
    }
}]);
app.controller('EditSubscriptionController', ["$scope", "AdminClient", "AdminErrorHandler", "$filter", "$location", "QueryParamParser", "PermissionsService", function($scope, AdminClient, AdminErrorHandler, $filter, $location, QueryParamParser, PermissionsService){
    'use strict';

    if(!PermissionsService.getPermissions().hasNewsletterRole){
        AdminErrorHandler.goToPermissionDeniedPage();
    }

    $scope.subscriptionErrors = {};

    $scope.cancel = function(){
        $location.path('view-subscription');
    };

    $scope.removePageError = function(field) {
        AdminErrorHandler.removePageError($scope.subscriptionForm, field, $scope.subscriptionErrors);
    };

    $scope.save = function(){
        var subscription = {
            title: $scope.subscription.title,
            subscriptionType: $scope.subscription.subscriptionTypeCode,
            country: $scope.subscription.countryOfOrigin,
            sortOrder: $scope.subscription.sortOrder,
            url: $scope.subscription.url,
            locale: $scope.subscription.locale,
            description: $scope.subscription.description,
            label: $scope.subscription.label
        };

        AdminClient.subscriptions.updateSubscription($scope.subscriptionId, subscription).then(
            function() {
                $location.path('/view-subscription')
            },
            function(response) {
                AdminErrorHandler.handleInputErrors(response.data, response.status, $scope.subscriptionErrors, $scope.subscriptionForm);
            }
        );
    };

    $scope.getSubscription = function() {
        return AdminClient.subscriptions.getSubscription($scope.subscriptionId).then(
            function(response){
                $scope.subscription = response.data;
                $scope.subscription.createDate = $filter('date')(new Date($scope.subscription.createDate), 'medium', 'en_US');
                $scope.subscription.lastModified = $filter('date')(new Date($scope.subscription.lastModified), 'medium', 'en_US');
            },
            function(){
                AdminErrorHandler.goToErrorPage();
            }
        );
    };

    $scope.subscriptionId = QueryParamParser.getQueryParams().subscriptionId;
    if($scope.subscriptionId) {
        AdminClient.constants.getCountries().then(
            function(response){
                $scope.countries = response.data;
            },
            function(){
                AdminErrorHandler.goToErrorPage();
            }
        );

        $scope.getSubscription().then(function(){
            $scope.pageReady = true;
        });
    }
    else {
        $scope.cancel();
    }
}]);
app.controller('CreateSubscriptionFilterController', ["$scope", "AdminClient", "AdminErrorHandler", "$filter", "$location", "QueryParamParser", "PermissionsService", function($scope, AdminClient, AdminErrorHandler, $filter, $location, QueryParamParser, PermissionsService){
    'use strict';

    if(!PermissionsService.getPermissions().hasNewsletterRole){
        AdminErrorHandler.goToPermissionDeniedPage();
    }

    $scope.subscriptionFilterErrors = {};
    $scope.subscriptionFilter = {};

    $scope.cancel = function(){
        $location.path('view-subscription');
    };

    $scope.removePageError = function(field) {
        AdminErrorHandler.removePageError($scope.subscriptionFilterForm, field, $scope.subscriptionFilterErrors);
    };

    $scope.save = function(){
        AdminClient.subscriptions.createSubscriptionInstance($scope.subscriptionId, $scope.subscriptionFilter).then(
            function(){
                $location.path('view-subscription');
            },
            function(){
                AdminErrorHandler.goToErrorPage();
            });
    };

    $scope.subscriptionId = QueryParamParser.getQueryParams().subscriptionId;
    if($scope.subscriptionId) {
        AdminClient.constants.marketing.getAffiliations().then(
            function(response) {
                $scope.affiliations = response.data;
            },
            function() {
                AdminErrorHandler.goToErrorPage();
            }
        );

        AdminClient.constants.getCountries().then(
            function(response) {
                $scope.countries = response.data;
                $scope.pageReady = true;
            },
            function() {
                AdminErrorHandler.goToErrorPage();
            }
        );
    }
    else {
        $scope.cancel();
    }
}]);
app.controller('EditSubscriptionFilterController', ["$scope", "AdminClient", "AdminErrorHandler", "$filter", "$location", "QueryParamParser", "PermissionsService", function($scope, AdminClient, AdminErrorHandler, $filter, $location, QueryParamParser, PermissionsService){
    'use strict';

    if(!PermissionsService.getPermissions().hasNewsletterRole){
        AdminErrorHandler.goToPermissionDeniedPage();
    }

    $scope.subscriptionFilterErrors = {};

    $scope.returnToViewSubscription = function(){
        $location.search('subscriptionInstanceId', null);
        $location.path('view-subscription');
    };

    $scope.removePageError = function(field) {
        AdminErrorHandler.removePageError($scope.subscriptionFilterForm, field, $scope.subscriptionFilterErrors);
    };

    $scope.save = function(){
        var request = {
            country: $scope.subscriptionFilter.countryCode,
            affiliation: $scope.subscriptionFilter.affiliation
        };

        AdminClient.subscriptions.updateSubscriptionInstance($scope.subscriptionInstanceId, request).then(
            function(){
                $scope.returnToViewSubscription();
            },
            function(){
                AdminErrorHandler.goToErrorPage();
            });
    };

    $scope.subscriptionInstanceId = QueryParamParser.getQueryParams().subscriptionInstanceId;
    $scope.subscriptionId = QueryParamParser.getQueryParams().subscriptionId;

    if($scope.subscriptionId && $scope.subscriptionInstanceId) {
        AdminClient.subscriptions.getSubscriptionInstance($scope.subscriptionInstanceId).then(
            function(response){
                $scope.subscriptionFilter = response.data;
                $scope.pageReady = true;
            },
            function(){
                AdminErrorHandler.goToErrorPage();
            });
        AdminClient.constants.marketing.getAffiliations().then(
            function(response) {
                $scope.affiliations = response.data;
            },
            function() {
                AdminErrorHandler.goToErrorPage();
            }
        );

        AdminClient.constants.getCountries().then(
            function(response) {
                $scope.countries = response.data;
            },
            function() {
                AdminErrorHandler.goToErrorPage();
            }
        );
    }
    else {
        $scope.cancel();
    }
}]);
app.controller('CreateSubscriptionController', ["$scope", "AdminClient", "AdminErrorHandler", "$filter", "$location", "PermissionsService", function($scope, AdminClient, AdminErrorHandler, $filter, $location, PermissionsService){
    'use strict';

    if(!PermissionsService.getPermissions().hasNewsletterRole){
        AdminErrorHandler.goToPermissionDeniedPage();
    }

    $scope.subscriptionErrors = {};
    $scope.disableCreateButton = false;

    $scope.cancel = function(){
        $location.path('manage-subscriptions');
    };

    $scope.removePageError = function(field) {
        AdminErrorHandler.removePageError($scope.subscriptionForm, field, $scope.subscriptionErrors);
    };

    $scope.save = function(){
        $scope.disableCreateButton = true;
        var subscription = {
            title: $scope.subscription.title,
            subscriptionType: $scope.subscription.subscriptionTypeCode,
            country: $scope.subscription.countryOfOrigin,
            sortOrder: $scope.subscription.sortOrder,
            url: $scope.subscription.url,
            locale: $scope.subscription.locale,
            description: $scope.subscription.description,
            label: $scope.subscription.label
        };

        AdminClient.subscriptions.createSubscription(subscription).then(
            function() {
                $location.path('/manage-subscriptions')
            },
            function(response) {
                AdminErrorHandler.handleInputErrors(response.data, response.status, $scope.subscriptionErrors, $scope.subscriptionForm);
                $scope.disableCreateButton = false;
            }
        );
    };

    AdminClient.constants.getCountries().then(
        function(response){
            $scope.countries = response.data;
        },
        function(){
            AdminErrorHandler.goToErrorPage();
        }
    );
}]);
app.controller('ImportTranslationsController', ["$scope", "AdminClient", "$location", "ADMIN_ERRORS", "AdminErrorHandler", "TranslationUtils", "PermissionsService", function($scope, AdminClient, $location, ADMIN_ERRORS, AdminErrorHandler, TranslationUtils, PermissionsService){
    'use strict';

    if(!PermissionsService.getPermissions().hasTranslationEditRole){
        AdminErrorHandler.goToPermissionDeniedPage();
    }

    //
    // state stuff
    //
    $scope.showImport  = true;
    $scope.showCalculating  = false;
    $scope.showSummary  = false;
    $scope.showFinished  = false;

    $scope.hasTranslationDiffs = false;

    $scope.diff = null;
    $scope.selectLocale = {};
    $scope.theInputFile = null;
    $scope.hasNewProperties = false;
    $scope.displayLocale = '';
    $scope.inputFile = null;


    // For diffs


    var findRoot = function(data) {
        var i, len;
        for (i = 0, len = data.length; i < len; i++) {
            if ( data[i] && data[i].code !== 'undefined' && "" == data[i].code)
                return data[i];
        }
        return data[0];
    }


    var getLocaleCodeForRemoteCall = function() {
        var selectLocaleCode = $scope.selectLocale.selected.code;

        if (selectLocaleCode == '' ) {
            selectLocaleCode = 'ROOT';
        }

        return selectLocaleCode;
    }


    var loadLocales = function() {
        AdminClient.locales.getLocales().then(function (response){ // Success
                $scope.selectLocale.allLocales = response.data;
                $scope.selectLocale.selected = findRoot(response.data);
            },
            function(){ // Error
                AdminErrorHandler.goToErrorPage();
            }
        );

    };


    $scope.handleDiffResponse = function() {
        $scope.hasTranslationDiffs = TranslationUtils.hasTranslationDiffs($scope.diff);
        $scope.hasNewProperties = TranslationUtils.hasNewProperties($scope.diff, $scope.selectedLocaleCode);
        $scope.showCalculating = false;
        $scope.showSummary = true;
    }




    $scope.uploadFile = function() {

        $scope.showImport = false;
        $scope.showCalculating = true;
        $scope.selectedLocaleCode = $scope.selectLocale.selected.code;
        $scope.displayLocale = $scope.selectLocale.selected.name;

        AdminClient.translations.calculateDiffForImport(getLocaleCodeForRemoteCall(), $scope.inputFile)
                .then(function (response) { // Success
                        $scope.diff = response.data;
                        $scope.handleDiffResponse();
                    },
                    function (response) { // Error
                        // there is no error handing... if we get an error something is wrong server side.
                        AdminErrorHandler.goToErrorPage();
                    }
                );

    }

    $scope.acceptImport = function () {
        $scope.showSummary = false;
        $scope.showCalculating = true;
        AdminClient.translations.doOverwriteWithImport(getLocaleCodeForRemoteCall(), $scope.inputFile)
            .then(function (response) { // Success
                    $scope.showCalculating = false;
                    $scope.showFinished = true;
                },
                function (response) { // Error
                    // there is no error handing... if we get an error something is wrong server side.
                    AdminErrorHandler.goToErrorPage();
                }
            );
    }


    $scope.backToHomePage = function () {
        $location.path('/');
    }




    loadLocales();

}]);
app.controller('ExportTranslationsController', ["$scope", "AdminClient", "$location", "ADMIN_ERRORS", "AdminErrorHandler", "PermissionsService", function($scope, AdminClient, $location, ADMIN_ERRORS, AdminErrorHandler, PermissionsService){
    'use strict';

    if(!PermissionsService.getPermissions().hasTranslationEditRole){
        AdminErrorHandler.goToPermissionDeniedPage();
    }

    $scope.selectLocale = {};
    $scope.selectLocale.selected = {};
    $scope.selectLocale.totalForLocale = 0;

    $scope.foundTranslationsGridOptions = {
        columnDefs: [
            {field: 'description', displayName: 'description', resizable:'true'},
             {field: 'name', displayName: 'classification', resizable:'true'},
            {field: 'count', width:200, resizable:'true'},
            {field: 'action', displayName: 'action', width:100, resizable:'true',
                cellTemplate:'<div class="ui-grid-cell-contents"><a id="remove-{{row.entity.name}}-button" ' +
                'ng-click="grid.appScope.downloadClassifications(row.entity.name)">{{row.entity.count > 0 && "export" || "" }}</a></div>'}
        ],
        enableGridMenu: false,
        gridMenuShowHideColumns: false
    };

    var findRoot = function(data) {
        var i, len;
        for (i = 0, len = data.length; i < len; i++) {
            if ( data[i] && data[i].code !== 'undefined' && "" == data[i].code)
                return data[i];
        }
        return data[0];
    }

    var totalForLocale = function(data) {
        var i, len;
        var count = 0;
        for (i = 0, len = data.length; i < len; i++) {
            if ( data[i] && 'all' == data[i].name) {
                count = data[i].count;
                break;
            }

            // if ( data[i] && data[i].count !== 'undefined')
            //     count += data[i].count;
        }
        return count;
    }



    var loadLocales = function() {
        AdminClient.locales.getLocales().then(function (response){ // Success
                $scope.selectLocale.allLocales = response.data;
                $scope.selectLocale.selected = findRoot(response.data);

            },
            function(){ // Error
                AdminErrorHandler.goToErrorPage();
            }
        );

    };



    var processDownload = function(response) {
        var headers = response.headers();
        var data = response.data;

        // format of content-disposition is
        var filename =  headers["content-disposition"] || "download.properties";
        var n = filename.indexOf("filename=");
        if ( n != -1) {
            filename = filename.substr(n + "filename=".length);
        }

        // see see https://stackoverflow.com/questions/38260859/download-the-byte-array-as-a-original-file-in-angularjs-from-server
        var contentType = headers["content-type"];// || "application/octet-stream";
        var urlCreator = window.URL || window.webkitURL || window.mozURL || window.msURL;
        if (urlCreator) {
            var blob = new Blob([data], {type: contentType});

            // see https://stackoverflow.com/questions/18394871/download-attribute-on-a-tag-not-working-in-ie
            if (window.navigator.msSaveBlob) { // IE10+ : (has Blob, but not a[download] or URL)
                window.navigator.msSaveBlob(blob, filename);
            } else {

                var url = urlCreator.createObjectURL(blob);


                var a = document.createElement("a");


                a.href = url;
                a.download = filename;
                a.target = '_blank';

                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            }
        }
    };


    $scope.findTranslationsForLocale = function() {
        $scope.foundTranslationsGridOptions.data = [];
        AdminClient.translations.getClassificationsStats($scope.selectLocale.selected.code)
            .then(function(response){ // Success
                    $scope.selectLocale.totalForLocale = totalForLocale(response.data);
                    $scope.foundTranslationsGridOptions.data = response.data;
                    addAction($scope.foundTranslationsGridOptions.data);
                },
                function(response){ // Error
                    // there is no error handing... if we get an error something is wrong server side.
                    AdminErrorHandler.goToErrorPage();
                }
            );
    };


    $scope.downloadClassifications = function(classification) {
        AdminClient.translations.downloadTranslationsForLocaleAndClassification($scope.selectLocale.selected.code, classification)
            .then(function(response){ // Success
                    processDownload(response);
                },
                function(response){ // Error
                    // there is no error handing... if we get an error something is wrong server side.
                    AdminErrorHandler.goToErrorPage();
                }
            );
    };


    loadLocales();
    $scope.findTranslationsForLocale();

}]);
app.controller('ManageTranslationVersionsController', ["$scope", "AdminClient", "$location", "ADMIN_ERRORS", "AdminErrorHandler", "PermissionsService", function($scope, AdminClient, $location, ADMIN_ERRORS, AdminErrorHandler, PermissionsService){
    'use strict';

    if(!PermissionsService.getPermissions().hasTranslationEditRole){
        AdminErrorHandler.goToPermissionDeniedPage();
    }

    $scope.backup = {};
    $scope.backup.backupReason = '';
    $scope.showLoadingVersions = false;
    $scope.transVersionGridOptions = {
        columnDefs: [
            {field: 'backupVersionId', width:100, displayName:'version', resizable:'true', sort: { direction: 'desc' }},
            {field: 'backupDate',  width:180, displayName: 'backupDate', resizable:'true',
                type: 'date', cellFilter: 'date:\'MM-dd-yyyy @ h:mma\''
            },
            {field: 'user', width:90, resizable:'true'},
            {field: 'type', width:120, resizable:'true'},
            {field: 'reason', width:380, resizable:'true'},
             {field: 'action', displayName: 'action', resizable:'true',
                 cellTemplate:'<div class="ui-grid-cell-contents">' +
                 '<a id="revert-{{row.entity.name}}-button" ng-click="grid.appScope.calculateDiffForRevert(row.entity.backupVersionId)" >REVERT</a>     |     ' +
                 '<a id="debug-{{row.entity.name}}-button" ng-click="grid.appScope.download(row.entity.backupVersionId)">download (for debug)</a></div>'
             }
        ],
        enableGridMenu: false,
        gridMenuShowHideColumns: false
    };

    //
    // diff stuff
    //
    $scope.diff = {};
    $scope.displayDiffs = false;
    $scope.showCalculating = false;
    $scope.showCreateBackup = true;
    $scope.showFinished = false;
    $scope.showOverwriting = false;
    $scope.selectedVersion;


    var loadVersions = function() {
        $scope.showLoadingVersions = true;


        AdminClient.translations.getVersions().then(function (response){ // Success
                $scope.transVersionGridOptions.data = response.data;
                $scope.showLoadingVersions = false;
            },
            function(){ // Error
                AdminErrorHandler.goToErrorPage();
            }
        );

    };


    $scope.calculateDiffForRevert = function(version) {
        $scope.showCreateBackup = false;
        $scope.showCalculating = true;
        $scope.selectedVersion = version;
        AdminClient.translations.calculateDiffForRevert(version)
            .then(function(response){ // Success
                    $scope.diff = response.data;
                    $scope.showCalculating = false;
                    $scope.displayDiffs = true;
                },
                function(response){ // Error
                    // there is no error handing... if we get an error something is wrong server side.
                    AdminErrorHandler.goToErrorPage();
                }
            );
    };

    $scope.completeOverwrite = function() {
        $scope.showOverwriting = true;
        AdminClient.translations.doRevert($scope.selectedVersion)
            .then(function(response){ // Success
                    $scope.showFinished = true;
                },
                function(response){ // Error
                    // there is no error handing... if we get an error something is wrong server side.
                    AdminErrorHandler.goToErrorPage();
                }
            );
    }



    var processDownload = function(response) {
        var headers = response.headers();
        var data = response.data;

        // format of content-disposition is
        var filename =  headers["content-disposition"] || "translations.json";
        var n = filename.indexOf("filename=");
        if ( n != -1) {
            filename = filename.substr(n + "filename=".length);
        }

        // see see https://stackoverflow.com/questions/38260859/download-the-byte-array-as-a-original-file-in-angularjs-from-server
        var contentType = headers["content-type"];// || "application/octet-stream";
        var urlCreator = window.URL || window.webkitURL || window.mozURL || window.msURL;
        if (urlCreator) {
            var blob = new Blob([data], {type: contentType});
            var url = urlCreator.createObjectURL(blob);
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = url;
            a.download = filename;
            a.click();
            window.URL.revokeObjectURL(url);
        }
    };



    $scope.download = function(version) {
        AdminClient.translations.downloadTranslationsForBackupVersion(version)
            .then(function(response){ // Success
                    processDownload(response);
                },
                function(response){ // Error
                    // there is no error handing... if we get an error something is wrong server side.
                    AdminErrorHandler.goToErrorPage();
                }
            );
    };


    $scope.doBackup = function() {
        AdminClient.translations.doBackup($scope.backup.backupReason)
            .then(function(response){ // Success
                    $scope.backup.backupReason = '';
                    $scope.manageVersionsForm.$setPristine();
                    loadVersions();
                },
                function(response){ // Error
                    // there is no error handing... if we get an error something is wrong server side.
                    AdminErrorHandler.goToErrorPage();
                }
            );
    };




    loadVersions();


}]);
app.controller('ManageTranslationsController', ["$scope", "AdminClient", "$location", "ADMIN_ERRORS", "AdminErrorHandler", "TranslationUtils", "$filter", "$window", "PermissionsService", function($scope, AdminClient, $location, ADMIN_ERRORS, AdminErrorHandler, TranslationUtils, $filter, $window, PermissionsService){
    'use strict';

    if(!PermissionsService.getPermissions().hasTranslationEditRole){
        AdminErrorHandler.goToPermissionDeniedPage();
    }

    $scope.allLocales = {};
    $scope.allLocaleCodes = [];

    $scope.allProperties = {};
    $scope.showAllProperties = false;
    $scope.showSingleProperty = false;
    $scope.showEditTranslation = false;
    $scope.selectedRow = null;
    $scope.selectedValue = null;


    var loadProperties = function() {
        AdminClient.translations.getProperties().then(function (response){ // Success
                $scope.allProperties = response.data;
                $scope.showAllProperties = true;
            },
            function(){ // Error
                AdminErrorHandler.goToErrorPage();
            }
        );
    };


    var extractJustCodes = function(allLocales) {
        $scope.allLocaleCodes = [];
        for (var i = 0, len = allLocales.length; i < len; i++) {
            $scope.allLocaleCodes.push(allLocales[i].code);
        }
    };


    var init = function() {
        AdminClient.locales.getLocales().then(function (response){ // Success
                $scope.allLocales = response.data;
                extractJustCodes($scope.allLocales);
                loadProperties();
            },
            function(){ // Error
                AdminErrorHandler.goToErrorPage();
            }
        );

    };



    var reselectRow = function(propName) {
        var row = $scope.findTranslationWithName(propName);
        if ( row != null ) {
            $scope.viewTranslationsForProperty(row);
        }
    }

    var backToSinglePropertyView = function(propName) {
        AdminClient.translations.getProperties().then(function (response){ // Success
                $scope.allProperties = response.data;
                reselectRow(propName)
            },
            function(){ // Error
                AdminErrorHandler.goToErrorPage();
            }
        );
    }


    //------------------------
    // functions on scope
    //

    $scope.findTranslationWithName = function(propName) {
        for (var i = 0, len = $scope.allProperties.length; i < len; i++) {
            var row = $scope.allProperties[i];
            if ( row.name == propName) {
                return row;
            }
        }
    }

    $scope.missingLocales = function(translation) {
        var missingFrom = new Set($scope.allLocaleCodes);

        for (var i = 0, len = translation.length; i < len; i++) {
            missingFrom.delete(translation[i].localeDefinition.code);
        }

        return Array.from(missingFrom);
    }


    $scope.viewTranslationsForProperty = function(row) {
        $scope.selectedRow = row;
        $scope.showEditTranslation = false;
        $scope.showSingleProperty = true;
        $scope.showAllProperties = false;
    }

    $scope.getTranslation = function(locale) {
        var ret = null;
        if ( null != $scope.selectedRow ) {
            var translations = $scope.selectedRow.translations;

            for (var i = 0, len = translations.length; i < len; i++) {
            if ( locale == translations[i].localeDefinition.code) {
                    ret = translations[i].value;
                    break;
               }
            }
        }

        return ret;
    }


    $scope.cancelEdit = function() {
        $scope.showSingleProperty = true;
        $scope.showEditTranslation = false;
    }

    // assume




    $scope.saveEdit = function() {
        $scope.showEditTranslation = false;
        AdminClient.translations.createOrUpdateTranslation($scope.selectedRow.name, $scope.selectedLocale, $scope.selectedValue).then(function (response){ // Success
                backToSinglePropertyView($scope.selectedRow.name);
            },
            function(){ // Error
                AdminErrorHandler.goToErrorPage();
            }
        );
    }

    $scope.editSingleTranslation = function(locale) {
        $scope.selectedLocale = locale;
        $scope.selectedValue=   $scope.getTranslation(locale);
        $scope.showSingleProperty = false;
        $scope.showEditTranslation = true;
    }

    $scope.deleteSingleTranslation = function(locale) {
        $scope.selectedLocale = locale;
        var deleteTranslation = $window.confirm('Are you sure you want to delete translation [' + $scope.selectedRow.name  + "] for locale [" +  $scope.selectedLocale + '] ?');

        if ( deleteTranslation ) {
            AdminClient.translations.deleteSingleTranslation($scope.selectedRow.name, $scope.selectedLocale).then(function (response) { // Success
                    backToSinglePropertyView($scope.selectedRow.name);
                },
                function () { // Error
                    AdminErrorHandler.goToErrorPage();
                }
            );
        }

    }

    $scope.createSingleTranslation = function(locale ) {
        $scope.selectedLocale = locale;
        $scope.selectedValue =   null;
        $scope.showSingleProperty = false;
        $scope.showEditTranslation = true;
    }


    $scope.backToFullPropertyList = function() {
        $scope.showEditTranslation = false;
        $scope.showSingleProperty = false;
        $scope.showAllProperties = true;
    }


    init();

}]);
app.controller('OverwriteFromRemoteTranslationsController', ["$scope", "AdminClient", "$location", "ADMIN_ERRORS", "AdminErrorHandler", "PermissionsService", function($scope, AdminClient, $location, ADMIN_ERRORS, AdminErrorHandler, PermissionsService){
    'use strict';

    if(!PermissionsService.getPermissions().hasTranslationEditRole){
        AdminErrorHandler.goToPermissionDeniedPage();
    }

    $scope.selectedEnvironment = {};
    $scope.selectedEnvironment.selected = {};
    $scope.selectedEnvironment.totalForLocale = 0;
    $scope.diff = {};
    $scope.displayDiffs = false;
    $scope.showCalculating = false;
    $scope.showFinished = false;
    $scope.showOverwriting = false;

    var loadEnvironments = function() {
        AdminClient.utilities.getEnvironments().then(function (response){ // Success
                $scope.selectedEnvironment.allEnvironments = response.data;
                $scope.selectedEnvironment.selected = '';

            },
            function(){ // Error
                AdminErrorHandler.goToErrorPage();
            }
        );
    };



    var processDiff = function(response) {
        $scope.diff = response.data;
        $scope.showCalculating = false;
        $scope.displayDiffs = true;
    };



    $scope.computeDiffsForEnvironment = function() {
        $scope.displayDiffs = false;
        $scope.showCalculating = true;
        AdminClient.translations.calculateDiffForRemote($scope.selectedEnvironment.selected.name)
            .then(function(response){ // Success
                    processDiff(response);
                },
                function(){ // Error
                    // nothing for user or client to do... error indicates something is wrong server side.
                    AdminErrorHandler.goToErrorPage();
                }
            );
    };


    $scope.acceptRemoteTranslations = function() {
        $scope.showOverwriting = true;
        AdminClient.translations.doOverwriteWithRemote($scope.selectedEnvironment.selected.name)
            .then(function(response){ // Success
                    $scope.showFinished = true;
                },
                function(response){ // Error
                    // nothing for user or client to do... error indicates something is wrong server side.
                    AdminErrorHandler.goToErrorPage();
                }
            );

    };



    loadEnvironments();

}]);
app.factory('TranslationUtils', function () {
    'use strict';
    
    return {

        hasTranslationDiffs : function(diff) {
            return diff !== null && diff.diffTranslationsByLocale && Object.keys(diff.diffTranslationsByLocale).length > 0;
        },


        keys : function() {
            return Object.keys(obj);
        },


        hasLocaleDiffs : function(diff) {
            return diff !== null && diff.localeDiffHolder && (diff.localeDiffHolder.newLocales.length > 0 ||
            diff.localeDiffHolder.changedLocales.length > 0 || diff.localeDiffHolder.deletedLocales.length > 0);
        },


        hasClassificationDiffs : function(diff) {
            return diff !== null && diff.classificationDiffHolder && (diff.classificationDiffHolder.newClassifications.length > 0
            || diff.classificationDiffHolder.changedClassifications.length > 0 || diff.classificationDiffHolder.deletedClassifications.length > 0);
        },

        hasPropertyDiffs : function(diff) {
            return diff !== null && diff.propertyDiffHolder && (diff.propertyDiffHolder.newProperties.length > 0 ||
                diff.propertyDiffHolder.changedProperties.length > 0 || diff.propertyDiffHolder.deletedProperties.length > 0);
        },

        hasAnyDiffs : function(diff) {
            var ret =  diff != null && (this.hasLocaleDiffs(diff) || this.hasClassificationDiffs(diff) || this.hasPropertyDiffs(diff) || this.hasTranslationDiffs(diff));

            return ret;
        },
        hasNewProperties : function(diff, locale) {
            // there are new properties.  we do no allow this on upload, they have to be manually added by developer

            var ret = false;

             if (diff.propertyDiffHolder != null &&
                 diff.propertyDiffHolder.newProperties != null &&
                 diff.propertyDiffHolder.newProperties.length > 0 ) {

                ret = true;
            }
            return ret;
        }

    };
});
app.directive('translationDiffSummary', function(){
    return {
        restrict: 'E',
        scope: {
            diff: '<',
            displayDiffs : '<',
            showCalculating : '<',
            showFinished : '<',
            showOverwriting : '<',
            onAccept : '='
        },
        templateUrl: 'partials/directives/TranslationDiffSummary.html',
        transclude: true,
        controller: ["$scope", "AdminClient", "$location", "AdminErrorHandler", "$window", function($scope, AdminClient, $location, AdminErrorHandler,  $window) {

            $scope.hasLocaleDiffs = function() {
                var localeDiffHolder = $scope.diff.localeDiffHolder;
                return localeDiffHolder != null &&
                (
                     localeDiffHolder.newLocales.length > 0 ||
                     localeDiffHolder.changedLocales.length > 0 ||
                     localeDiffHolder.deletedLocales.length > 0
                );
            };

            $scope.hasClassificationDiffs = function() {
                var classificationDiffHolder = $scope.diff.classificationDiffHolder;
                return classificationDiffHolder != null &&
                    (
                        classificationDiffHolder.newClassifications.length > 0 ||
                        classificationDiffHolder.changedClassifications.length > 0 ||
                        classificationDiffHolder.deletedClassifications.length > 0
                );
            };


            $scope.hasPropertyDiffs = function() {
                var propertyDiffHolder = $scope.diff.propertyDiffHolder;
                return propertyDiffHolder != null &&
                    (
                    propertyDiffHolder.newProperties.length > 0 ||
                    propertyDiffHolder.changedProperties.length > 0 ||
                    propertyDiffHolder.deletedProperties.length > 0
                    );
            };

            $scope.hasTranslationDiffs = function() {
                var diffTranslationsByLocale = $scope.diff.diffTranslationsByLocale;
                return diffTranslationsByLocale != null &&
                    (
                        $scope.Utils.keys(diffTranslationsByLocale).length > 0
                    );
            };


            $scope.hasAnyDiffs = function() {
                var ret =  $scope.displayDiffs && ($scope.hasLocaleDiffs() || $scope.hasClassificationDiffs() || $scope.hasPropertyDiffs() || $scope.hasTranslationDiffs());
                return ret;
            };


            $scope.Utils = {
                keys : function(version) { if (version) return Object.keys(version);}
            }

            $scope.cancel = function() {
                $window.location.href = ''; // back to home
            }

        }]
    };
});
app.controller('ManageApplicationKeysController', ["$scope", "AdminClient", "$location", "ADMIN_ERRORS", "AdminErrorHandler", "PermissionsService", function($scope, AdminClient, $location, ADMIN_ERRORS, AdminErrorHandler, PermissionsService){
    'use strict';

    if(!PermissionsService.getPermissions().hasProfileConfigureRole){
        AdminErrorHandler.goToPermissionDeniedPage();
    }

    $scope.allApplications = {};
    $scope.allPermissions = {};
    $scope.rawAllPermissions = {};
    $scope.appAndPermissionsFormErrors = {};

    $scope.showAllApplications = false;
    $scope.showSingle = false;
	$scope.showPropagate = false;
    $scope.applicationModel = {};

	$scope.propagateOptionsModel = {};
	$scope.propagateResults = null;
	$scope.propagateError = false;

    $scope.busy = false;

    // initial values, may be changed in processExistingPermissions below
    $scope.version = '2';
    $scope.domain = 'internal';

    //
    // any -- on initialization, no domain has been selected
    // all - both internal and external domain selected, legacy
    // internal - internal domain, disable external domain
    // external -- external domain, disable internal domain
    //
    $scope.showDomainTab = 'any';

    var loadAllKeys = function(showAllApps) {
        AdminClient.applications.getAllKeys().then(function (response){ // Success
                $scope.allApplications = response.data;
                $scope.showAllApplications = showAllApps;
            },
            function(){ // Error
                AdminErrorHandler.goToErrorPage();
            }
        );
    };

    var orderPermissions = function(rawAllPermissions, existingPermissions) {
        processExistingPermissions(rawAllPermissions, existingPermissions);

        $scope.rawAllPermissions = rawAllPermissions;

        $scope.allPermissions = {};
        for (var i = 0, len = rawAllPermissions.length; i < len; i++) {
            var row = rawAllPermissions[i];

            // <!-- [{"permissionID":1,"type":"application","domain":"internal","operation":"create","version":"2"} -->
            var domainMap = $scope.allPermissions[row.version];
            if ( !domainMap) {
                domainMap = {};
                $scope.allPermissions[row.version] = domainMap;
            }
            var typeMap = domainMap[row.domain];
            if ( !typeMap) {
                typeMap = {};
                domainMap[row.domain] = typeMap;
            }
            var operationArr = typeMap[row.type];
            if ( !operationArr) {
                operationArr = [];
                typeMap[row.type] = operationArr;
            }

            operationArr.push(row);
        }
    };

    var processExistingPermissions = function(rawAllPermissions, existingPermissions) {
        $scope.showDomainTab = 'none';
        var selectedInitial = false;

        for (var i = 0; i <  existingPermissions.length; i++) {
            var appPermRow = existingPermissions[i];

            //
            // If we already have multiple domains selected from read show warning, else allow for disable
            //
            if ( $scope.showDomainTab == 'none') {
                $scope.showDomainTab = appPermRow.domain;
            } else {
                if ( $scope.showDomainTab != 'all') {
                    if ( appPermRow.domain != $scope.showDomainTab) {
                        alert("WARNING!!\nApplication keys should have internal -OR- external permissions.\nThis key has both. \nPlease correct ASAP.");
                        $scope.showDomainTab = 'all';
                    }
                }
            }

            for (var j = 0; j < rawAllPermissions.length; j++) {
                 var rawRow = rawAllPermissions[j];

                if ( rawRow.permissionID == appPermRow.permissionID ) {
                    rawRow.selected = true;

                    if ( !selectedInitial ) {
                        $scope.version = rawRow.version;
                        $scope.domain = rawRow.domain;
                        selectedInitial = true;
                    }

                    break;
                }
            }
        }

        // if there were no permission set choose 'any'
        if ( $scope.showDomainTab == 'none') {
            $scope.showDomainTab = 'any';
        }
    };

    var init = function() {
        loadAllKeys(true);
    };

    $scope.togglePerm = function(perm) {
        // only change the allowble domain if it has not already been set and is not "all"
        if (  $scope.showDomainTab == 'any') {
            $scope.showDomainTab = perm.domain;
        }
    };

    var reloadUser = function(id) {
        // reload what we're editing
        AdminClient.applications.getSingleApp(id).then(function (response){ // Success
                $scope.applicationModel = response.data;
                loadAllKeys(false);
                $scope.appAndPermissionsForm.$setPristine();
            },
            function(){ // Error
                AdminErrorHandler.goToErrorPage();
            }
        );
    };

    var toggleEnableDisable = function(id, flag) {
        AdminClient.applications.setApplicationEnabled(id, flag).then(function (){ // Success
                reloadUser(id);
            },
            function(){ // Error
                AdminErrorHandler.goToErrorPage();
            }
        );
    };

    $scope.disable = function() {
        toggleEnableDisable($scope.applicationModel.id, false);
    };

    $scope.isCreate = function() {
        return null == $scope.applicationModel.id;
    };

    var collectPerms = function() {
        var selectedPermissions = [];
        for (var j = 0; j < $scope.rawAllPermissions.length; j++) {
            var rawRow = $scope.rawAllPermissions[j];
            if (rawRow.selected) {
                selectedPermissions.push(rawRow);
            }
        }

        return selectedPermissions;
    };

    $scope.update = function() {
        var selectedPermissions = collectPerms();

        AdminClient.applications.updateApplication($scope.applicationModel).then(function (response){ //
                AdminClient.applications.updatePermissions($scope.applicationModel.id, selectedPermissions).then(function (response){ //
                        reloadUser($scope.applicationModel.id);
                    },
                    function(){ // Error
                        AdminErrorHandler.goToErrorPage();
                    }
                );
            },
            function(response){ // Error
                AdminErrorHandler.handleInputErrors(response.data, response.status, $scope.appAndPermissionsFormErrors, $scope.appAndPermissionsForm);
            }
        );
    };

    $scope.create = function() {
        var selectedPermissions = collectPerms();

        AdminClient.applications.createApplication($scope.applicationModel).then(function (response){ //
                var urlParts = response.headers('Location').split('/');
                var appID = urlParts[urlParts.length - 1];

                $scope.applicationModel.id = appID;

                AdminClient.applications.getSingleApp(appID).then(function (response){ // Success
                        $scope.applicationModel = response.data;
                        AdminClient.applications.updatePermissions(appID, selectedPermissions).then(function (response){ //
                                loadAllKeys(false);
                                $scope.appAndPermissionsForm.$setPristine();
                            },
                            function(){ // Error
                                AdminErrorHandler.goToErrorPage();
                            }
                        );
                    },
                    function(){ // Error
                        AdminErrorHandler.goToErrorPage();
                    }
                );
            },
            function(response){ // Error
                AdminErrorHandler.handleInputErrors(response.data, response.status, $scope.appAndPermissionsFormErrors, $scope.appAndPermissionsForm);
            }
        );
    };

    $scope.enable = function() {
       toggleEnableDisable($scope.applicationModel.id, true);
    };

    $scope.viewApplicationForRow = function(row) {
        $scope.applicationModel = row;

        AdminClient.applications.getSingleAppPermissions(row.id).then(function (response){ // Success
                var existingPermissions = response.data

                AdminClient.applications.getAllPermissions().then(function (response){ // Success
                        $scope.flatPermissions = response.data;
                        orderPermissions(response.data, existingPermissions);
                        $scope.showAllApplications = false;
                        $scope.showSingle = true;
                    },
                    function(){ // Error
                        AdminErrorHandler.goToErrorPage();
                    }
                );
            },
            function(){ // Error
                AdminErrorHandler.goToErrorPage();
            }
        );
    };

    $scope.selectVersion = function (setTab){
        $scope.version = setTab;
    };

    $scope.isSelectedVersion = function(checkTab) {
        return $scope.version === checkTab;
    };

    $scope.selectDomain = function (setTab){
        $scope.domain = setTab;
    };

    $scope.isSelectedDomain = function(checkTab) {
        return $scope.domain === checkTab;
    };

    $scope.showDomain = function (setTab){
        return $scope.showDomainTab == 'any' || $scope.showDomainTab == 'all' || $scope.showDomainTab == setTab;
    };

    $scope.returnToList = function (){
        $scope.showSingle = false;
	    $scope.showPropagate = false;
        $scope.showAllApplications = true;
    };

	$scope.returnToEdit = function (){
		$scope.showSingle = true;
		$scope.showPropagate = false;
		$scope.showAllApplications = false;
	};

    $scope.createEdit = function (){
        $scope.applicationModel = {};

        AdminClient.applications.getAllPermissions().then(function (response){ // Success
                        $scope.flatPermissions = response.data;
                        orderPermissions(response.data, []);
                        $scope.showAllApplications = false;
                        $scope.showSingle = true;
            },
            function(){ // Error
               AdminErrorHandler.goToErrorPage();
        });
    };

	$scope.showPropagateFunc = function(){
	    $scope.propagateOptionsModel.toThycotic = true;
		$scope.propagateOptionsModel.toOtherEnvironments = true;
		$scope.propagateOptionsModel.includePermissions = true;

		$scope.propagateResults = null;

	    $scope.showSingle = false;
	    $scope.showPropagate = true;
	};

	$scope.propagate = function() {
	    $scope.propagateError = false;
	    $scope.busy = true;
		AdminClient.applications.propagateApplication($scope.applicationModel.id,$scope.propagateOptionsModel.toThycotic,$scope.propagateOptionsModel.toOtherEnvironments,$scope.propagateOptionsModel.includePermissions).then(function (response){ // Success
				$scope.propagateResults = response.data;
                $scope.busy = false;
			},
			function(response){ // Error
				$scope.propagateError = true;
				$scope.propagateResults = response.status + " " + response.statusText;
				$scope.busy = false;
			}
		);
	};

    $scope.removeAppkeyPageError = function(field) {
        AdminErrorHandler.removePageError($scope.appAndPermissionsForm, field, $scope.appAndPermissionsFormErrors);
    };

    init();
}]);
app.controller('ViewAccessRestrictionsController', ["$scope", "AdminClient", "AdminErrorHandler", "$filter", "$location", "ADMIN_CONSTANTS", "ADMIN_ERRORS", "PermissionsService", function($scope, AdminClient, AdminErrorHandler, $filter, $location, ADMIN_CONSTANTS, ADMIN_ERRORS, PermissionsService){
    'use strict';

    if(!PermissionsService.getPermissions().hasAccessControlRole){
        AdminErrorHandler.goToPermissionDeniedPage();
    }

    $scope.goToViewUserAccessRestrictions = function(idOrEmail) {
        $location.search('idOrEmail', idOrEmail);
        $location.path('/view-user-access-restrictions');
    };

    $scope.index = 1;

    $scope.isDeleted = function(str) {
        return str === '[deleted]';
    };

    $scope.accessRestrictionsGridOptions = {
        columnDefs: [
            {field: 'accessRestriction.profileId', displayName:'Profile Id', width:75, resizable:'true', sort: { direction: 'asc', priority: 0 } ,cellTemplate:'<div class="ui-grid-cell-contents">' +
                '<a href ng-if="!grid.appScope.isDeleted(grid.getCellValue(row, grid.columns[index+1]))" ng-click="grid.appScope.goToViewUserAccessRestrictions(grid.getCellValue(row, col))">{{grid.getCellValue(row, col)}}</a>' +
                '<a ng-if="grid.appScope.isDeleted(grid.getCellValue(row, grid.columns[index+1]))">{{grid.getCellValue(row, col)}}</a></div></div>'},
            {field: 'email', displayName:'Email', minWidth:300, resizable:'true', cellTemplate:'<div class="ui-grid-cell-contents">' +
                '<a href ng-if="!grid.appScope.isDeleted(grid.getCellValue(row, col))" ng-click="grid.appScope.goToViewUserAccessRestrictions(grid.getCellValue(row, col))">{{grid.getCellValue(row, col)}}</a>' +
                '<a ng-if="grid.appScope.isDeleted(grid.getCellValue(row, col))">{{grid.getCellValue(row, col)}}</a></div>'},
            {field: 'firstName', displayName: 'First Name', minWidth:200, resizable:'true'},
            {field: 'lastName', displayName: 'Last Name', minWidth:200, resizable:'true'},
            {field: 'accessRestriction.matchDate', displayName:'Match Date', minWidth:150, resizable:'true'},
            {field: 'accessRestriction.matchSource', displayName:'Source', minWidth:100, resizable:'true'},
            {field: 'accessRestriction.matchType', displayName:'Match Type', minWidth:100, resizable:'true'},
            {field: 'accessRestriction.matchValue', displayName:'Match Value', minWidth:200, resizable:'true'},
            {field: 'accessRestriction.accessAllowed', displayName:'Access', minWidth:75, resizable:'true'},
            {field: 'accessRestriction.reviewedBy', displayName:'Reviewed By', minWidth:200, resizable:'true'},
            {field: 'accessRestriction.reviewDate', displayName:'Review Date', minWidth:150, resizable:'true'}
        ],
        useExternalPagination: true,
        enablePaginationControls: false,
        enableGridMenu: false,
        enableSorting: false
    };

    $scope.decreaseIndex = function() {
        if ($scope.index > 1) {
            $scope.pageReady = false;
            $scope.index -= 1;
            $scope.getAllAccessRestrictions();
        }
    };

    $scope.increaseIndex = function() {
        if ($scope.accessRestrictionsGridOptions.data.length === ADMIN_CONSTANTS.ACCESS_RESTRICTIONS_PAGE_SIZE) {
            $scope.pageReady = false;
            $scope.index += 1;
            $scope.getAllAccessRestrictions();
        }
    };

    $scope.lastIndex = function() {
        if ($scope.accessRestrictionsGridOptions.data.length === ADMIN_CONSTANTS.ACCESS_RESTRICTIONS_PAGE_SIZE) {
            $scope.pageReady = false;
            $scope.index = $scope.pageCount;
            $scope.getAllAccessRestrictions();
        }
    };

    $scope.updateIndex = function(index) {
        $scope.pageReady = false;
        $scope.index = index;
        $scope.getAllAccessRestrictions();
    };

    $scope.getAllAccessRestrictions = function() {
        AdminClient.accessChecks.getAllAccessRestrictions($scope.index).then(
            function(response){
                $scope.accessRestrictionsGridOptions.data = response.data;
                $scope.accessRestrictionsGridOptions.data.forEach(function updateRows(row) {
                    row.matchDate = $filter('date')(new Date(row.matchDate), 'medium', 'en_US');
                    row.reviewDate = $filter('date')(new Date(row.reviewDate), 'medium', 'en_US');
                });
                $scope.pageReady = true;
            },
            function(error) {
                if (error.status === ADMIN_ERRORS.SERVICE_UNAVAILABLE_ERROR){
                    $scope.accessRestrictionsGridOptions = null;
                    $scope.pageReady = true;
                }else {
                    AdminErrorHandler.goToErrorPage();
                }
            }
        );
    };

    AdminClient.accessChecks.getAccessRestrictionCount().then(
        function(response){
            var count = response.data;
            $scope.pageCount = Math.ceil(count/ADMIN_CONSTANTS.ACCESS_RESTRICTIONS_PAGE_SIZE);
            $scope.pages = [];

            for(var i=1; i<=$scope.pageCount; i++) {
                $scope.pages.push(i);
            }
        },
        function(error) {
            if (error.status === ADMIN_ERRORS.SERVICE_UNAVAILABLE_ERROR){
                $scope.accessRestrictionsGridOptions = null;
            }else {
                AdminErrorHandler.goToErrorPage();
            }
        }
    );

    $scope.getAllAccessRestrictions();
}]);

app.controller('ViewUserAccessRestrictionsController', ["$scope", "AdminClient", "AdminErrorHandler", "$filter", "$location", "QueryParamParser", "PermissionsService", function($scope, AdminClient, AdminErrorHandler, $filter, $location, QueryParamParser, PermissionsService){
    'use strict';

    if(!PermissionsService.getPermissions().hasAccessControlRole){
        AdminErrorHandler.goToPermissionDeniedPage();
    }

    $scope.idOrEmail = QueryParamParser.getQueryParams().idOrEmail;

    $scope.cancel = function(){
        $location.search('idOrEmail', null);
        $location.path('view-access-restrictions');
    };
}]);

app.controller('AccessStatusSearchController', ["$scope", "AdminClient", "ADMIN_ERRORS", "AdminErrorHandler", "PermissionsService", function($scope, AdminClient, ADMIN_ERRORS, AdminErrorHandler, PermissionsService){
    'use strict';

    if(!PermissionsService.getPermissions().hasProfileViewRole){
        AdminErrorHandler.goToPermissionDeniedPage();
    }

    $scope.searchAccessStatusErrors = {};
    $scope.searchEntered = false;

    $scope.findAccessStatus = function() {
        AdminClient.accessChecks.getAccessStatus($scope.idOrEmail)
            .then(function(response){ // Success
                    $scope.accessStatusResults = response.data;
                    $scope.searchEntered = true;
                },
                function(response){ // Error
                    if(response.status === ADMIN_ERRORS.SERVICE_UNAVAILABLE_ERROR) {
                        $scope.serviceunavailable = true;
                    }
                    AdminErrorHandler.handleInputErrors(response.data, response.status, $scope.searchAccessStatusErrors, $scope.accessStatusSearchForm);
                }
            );

    };

    $scope.removePageError = function() {
        AdminErrorHandler.removePageError($scope.accessStatusSearchForm, 'idOrEmail', $scope.searchAccessStatusErrors);
        $scope.searchEntered = false;
    };
}]);

app.controller('ServiceStatusController', ["$scope", "AdminClient", "PermissionsService", "AdminErrorHandler", function($scope, AdminClient, PermissionsService, AdminErrorHandler){
    'use strict';

    if(!PermissionsService.getPermissions().hasProfileConfigureRole){
        AdminErrorHandler.goToPermissionDeniedPage();
    }

    $scope.serviceStatusConfig = {};

    $scope.runServiceStatus = function() {
        delete $scope.serviceStatus;
        if($scope.serviceStatusConfig.version == 'BOTH') {
            AdminClient.utilities.getServiceStatus($scope.serviceStatusConfig.environment, "stable")
                .then(function(response) { // Success
                    if(!$scope.serviceStatus) {
                        $scope.serviceStatus = response.data;
                    }
                    else {
                        $scope.serviceStatus = $scope.serviceStatus.concat(response.data);
                    }
                    $scope.successfulServiceCalls();
                });

            AdminClient.utilities.getServiceStatus($scope.serviceStatusConfig.environment, "unstable")
                .then(function(response) { // Success
                    if(!$scope.serviceStatus) {
                        $scope.serviceStatus = response.data;
                    }
                    else {
                        $scope.serviceStatus = $scope.serviceStatus.concat(response.data);
                    }
                    $scope.successfulServiceCalls();
                });
        }
        else {
            AdminClient.utilities.getServiceStatus($scope.serviceStatusConfig.environment, $scope.serviceStatusConfig.version)
                .then(function(response) { // Success
                    $scope.serviceStatus = response.data;
                    $scope.successfulServiceCalls();
                });
        }
    };

    $scope.statusOk = function(status) {
        return status.statusCode == 403 || status.statusCode == 401;
    };

    $scope.successfulServiceCalls = function() {
        $scope.successfulStatusCounter = 0;
        for(var i=0; i<$scope.serviceStatus.length; i++) {
            if($scope.statusOk($scope.serviceStatus[i])){
                $scope.successfulStatusCounter++;
            }
        }
    };
}]);
app.controller('ManageEnvironmentController', ["$scope", "AdminClient", "PermissionsService", "AdminErrorHandler", function($scope, AdminClient, PermissionsService, AdminErrorHandler){
    'use strict';

    if(!PermissionsService.getPermissions().hasProfileConfigureRole){
        AdminErrorHandler.goToPermissionDeniedPage();
    }

    var profileEnvProp = 'config.profile.all.live.environment';
    var optinEnvProp = 'config.optin.live.environment';
    var profileEmailPauseProp = 'config.profile.all.email.pause';
    var optinEmailPauseProp = 'config.optin.email.pause';


    $scope.profile_settings =  {};
    $scope.optin_settings =  {};

    $scope.profile_env =  'unavailable';
    $scope.optin_env ='unavailable';

    $scope.profile_email_pause_text = 'unavailable';
    $scope.optin_email_pause_text = 'unavailable';

    $scope.profile_error = '';
    $scope.optin_error = '';


    var getKeyValue = function(key, response)  {

        var ret = response[key];
        if ( ret ) {
            return response[key].value;
        }
        return 'unavailable';
    }


    var loadCurrentProfileEnv = function()  {

        AdminClient.environment.getProfileInternalSettings().then(function (response){ // Success
                $scope.profile_settings = response.data;
                $scope.profile_env = getKeyValue(profileEnvProp,$scope.profile_settings );
                $scope.profile_email_pause_text = getKeyValue(profileEmailPauseProp,$scope.profile_settings) == 'true' ? 'paused' : 'active';
            },
            function(){ // Error
                $scope.profile_error ='Something went wrong loading profile settings, reload this page';
            }
        );
    };

    var loadCurrentOptinEnv = function()  {

        AdminClient.environment.getOptinInternalSettings().then(function (response){ // Success
                $scope.optin_settings = response.data;
                $scope.optin_env = getKeyValue(optinEnvProp,$scope.optin_settings);
                $scope.optin_email_pause_text = getKeyValue(optinEmailPauseProp,$scope.optin_settings) == 'true' ? 'paused' : 'active';
            },
            function(){ // Error
                $scope.optin_error ='Something went wrong loading optin settings, reload this page';
            }
        );
    };



    var oppositeEnv = function(env) {
        if ( env == 'green') {
            return 'blue'
        } else if ( env == 'blue' ) {
            return 'green'
        } else {
            return 'unavailable'
        }
    };

    var oppositeBoolean = function(val) {
        if ( val == 'true') {
            return 'false'
        } else if ( val == 'false' ) {
            return 'true'
        } else {
            return 'unavailable'
        }
    };



    loadCurrentProfileEnv();
    loadCurrentOptinEnv();


    //
    //

    var updateProfileInternalSetting = function(key, newValue) {

        if ( !newValue || newValue == 'unavailable') {
            $scope.profile_error ='Can\'t set profile setting ' + key + ' to ' + newValue;
            return;
        }

        AdminClient.environment.updateProfileInternalSettings(key, newValue).then(function (){ // Success
                loadCurrentProfileEnv();
            },
            function(){ // Error
                $scope.profile_error ='Something went wrong updating profile setting ' + key + ' to ' + newValue;
            }
        );
    };

    var updateOptinInternalSetting = function(key, newValue) {

        if ( !newValue || newValue == 'unavailable') {
            $scope.profile_error ='Can\'t set optin setting ' + key + ' to ' + newValue;
            return;
        }


        AdminClient.environment.updateOptinInternalSettings(key, newValue).then(function (){ // Success
                loadCurrentOptinEnv();
            },
            function(){ // Error
                $scope.optin_error ='Something went wrong updating optin setting ' + key + ' to ' + newValue;
            }
        );
    };


    var toggleProfileBoolean = function(key) {
        updateProfileInternalSetting(key,oppositeBoolean( getKeyValue(key,$scope.profile_settings)));
    };


    var toggleOptinBoolean = function(key) {
        updateOptinInternalSetting(key,oppositeBoolean( getKeyValue(key,$scope.optin_settings)));
    };


    $scope.toggleProfileEnv = function() {
        $scope.profile_env =  'unavailable';
        updateProfileInternalSetting(profileEnvProp,oppositeEnv( getKeyValue(profileEnvProp,$scope.profile_settings)));
    };

    $scope.toggleProfileEmail = function() {
        $scope.profile_email_pause_text = 'unavailable';
        toggleProfileBoolean(profileEmailPauseProp);
    };

    $scope.toggleOptinEnv = function() {
        $scope.optin_env ='unavailable';
        updateOptinInternalSetting(optinEnvProp,oppositeEnv( getKeyValue(optinEnvProp,$scope.optin_settings)));
    };

    $scope.toggleOptinEmail = function() {
        $scope.optin_email_pause_text = 'unavailable';
        toggleOptinBoolean(optinEmailPauseProp);
    };


}]);
app.controller('ManageInternalSettingsController', ["$scope", "AdminClient", "$location", "ADMIN_ERRORS", "AdminErrorHandler", "PermissionsService", function($scope, AdminClient, $location, ADMIN_ERRORS, AdminErrorHandler, PermissionsService){
	'use strict';

	if(!PermissionsService.getPermissions().hasProfileConfigureRole){
		AdminErrorHandler.goToPermissionDeniedPage();
	}

	$scope.allSettings = {};
    $scope.allSettingsLength = null;

    $scope.isEdit = false;
    $scope.isSaving = false;

    $scope.showList = false;
    $scope.showCreateEdit = false;

    $scope.setting = {};
	$scope.settingErrors = {};

    var loadAllSettingsView = function() {
        AdminClient.environment.getProfileInternalSettings().then(
        	function(response) { // Success
                $scope.allSettings = response.data;
		        $scope.allSettingsLength = Object.keys($scope.allSettings).length;

                $scope.showList = true;
		        $scope.showCreateEdit = false;

		        $scope.setting.name = null;
		        $scope.setting.value = null;
		        $scope.isEdit = false;
		        $scope.isSaving = false;
		        AdminErrorHandler.removePageErrors($scope.settingForm, ['name', 'value'], $scope.settingErrors);
            },
            function() { // Error
                AdminErrorHandler.goToErrorPage();
            }
        );
    };

	$scope.createEdit = function(row) {
		if(row) {
			$scope.setting.name = row.name;
			$scope.setting.value = row.value;
			$scope.isEdit = true;
		} else {
			$scope.setting.name = null;
			$scope.setting.value = null;
			$scope.isEdit = false;
		}
		AdminErrorHandler.removePageErrors($scope.settingForm, ['name', 'value'], $scope.settingErrors);

		$scope.showList = false;
		$scope.showCreateEdit = true;
	};

	$scope.cancel = function() {
		$scope.showList = true;
		$scope.showCreateEdit = false;

		$scope.setting.name = null;
		$scope.setting.value = null;
		$scope.isEdit = false;
		AdminErrorHandler.removePageErrors($scope.settingForm, ['name', 'value'], $scope.settingErrors);
	};

	$scope.change = function(element) {
		AdminErrorHandler.removePageErrors($scope.settingForm, element, $scope.settingErrors);
	};

	$scope.save = function() {
		$scope.isSaving = true;
		var saveMethod;
		if($scope.isEdit) {
			saveMethod = AdminClient.environment.updateProfileInternalSettings;
		} else {
			saveMethod = AdminClient.environment.createProfileInternalSettings;
		}

		saveMethod($scope.setting.name, $scope.setting.value).then(
			function() { // Success
				loadAllSettingsView();
			},
			function(response){ // Error
				AdminErrorHandler.handleInputErrors(response.data, response.status, $scope.settingErrors, $scope.settingForm);
				$scope.isSaving = false;
			}
		);
	};

    loadAllSettingsView();
}]);

app.controller('ErrorController', ["$scope", "$location", function ($scope, $location) {
    'use strict';

    (function () {
        if(!$scope.$parent.globalError) {
            $location.path('/');
        }
    })();
}]);
